import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useContext  } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import { CLIENT_URL } from '../helpers/constants'

function Menu(props) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { authState, setAuthState } = useContext(AuthContext);
    
    useEffect(() => {
        if (!localStorage.getItem("accessToken")) {
            navigate("/login");
        }
        // eslint-disable-next-line
      }, []);

    const logout = () => {
        localStorage.removeItem("accessToken");        
        setAuthState({
            email: '',
            id: 0,
            companyid: 0,
            status: false,
          });
        navigate("/");
      };

    return (
        <Navbar expand="sm" className="bg-body-tertiary shadow" sticky='top'>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <NavDropdown style={{marginLeft:'10px'}} title={authState.email}>
              <NavDropdown.Item href="#" onClick={()=>{logout()}}>Logout</NavDropdown.Item>              
            </NavDropdown>
            <Navbar.Collapse id="navbarScroll">
                <Nav
                    variant="underline"
                    className="container-fluid justify-content-end my-2 my-lg-0"
                    style={{ maxHeight: '240px' }}
                    navbarScroll
                    defaultActiveKey={props.ActiveKey}
                >
                    <Nav.Item>
                        <Nav.Link href={CLIENT_URL + "/main"}>{t('app.Main')}</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link href={CLIENT_URL + "/branches"}>{t('app.Branches')}</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link href={CLIENT_URL + "/polls"}>{t('app.Polls')}</Nav.Link>
                    </Nav.Item>
                    
                    <Nav.Item>
                        <Nav.Link href={CLIENT_URL + "/metrics"}>{t('app.Metrics')}</Nav.Link>
                    </Nav.Item>
                    
                </Nav>                
            </Navbar.Collapse>                        
        </Navbar>        
    );
}

export default Menu;