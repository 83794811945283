import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import * as formik from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'

function RegisterFast() {
    const { t } = useTranslation();
    const { link } = useParams();
    let navigate = useNavigate();
    const { Formik } = formik;
    const [err, setErr] = useState('2001');
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const schema = yup.object().shape({
        companyname: yup.string().required(t('app.CompanyWaiting')),
        branchname: yup.string().required(t('app.BranchWaiting')),
        email: yup.string().email().required(t('app.EmailWaiting')),
        password: yup.string().min(3, "min 3").max(20, "max 20").required(t('app.PassWaiting'))
      });
    const initialValues = {
        companyname: "",
        branchname: "",
        branchaddress: "",
        email: "",
        password: ""
      };
    
  const onsubmit = (data) => {
    axios.post(SERVER_URL+"/user/registerfast/" + link, data).then((response) => {
      if (response.data.error) {
        setErr(response.data.error);
        setShow(true);
      } else {
        navigate("/go/" + link);
      }
    });
  };

  const home = () => {
    navigate("/");
  };

  return (
    <>
    <Formik
        validationSchema={schema}
        onSubmit={onsubmit}
        initialValues={initialValues}
    >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="row align-items-center vh-100" noValidate onSubmit={handleSubmit}>
                <Col sm={6} xs={11} className="mx-auto">
                    <Card className="shadow">
                        <Card.Header>{t('app.Registration')}</Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="formBasicCompany">
                                <Form.Label>{t('app.CompanyName')}</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="companyname"
                                    value={values.companyname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.companyname} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.companyname}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicBranch">
                                <Form.Label>{t('poll.branch')}</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="branchname"
                                    value={values.branchname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.branchname} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.branchname}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicAddress">
                                <Form.Label>{t('app.address')}</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="branchaddress"
                                    value={values.branchaddress}
                                    onChange={handleChange}
                                    isInvalid={!!errors.branchaddress} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.branchaddress}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{t('app.Pass')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">{t('app.Registration')}</Button>
                            <Button variant="link" onClick={home}>{t('app.Home')}</Button>
                        </Card.Body>
                    </Card>
                    <Card>
                        <Card.Body>
                            {t('app.FastRegPayInfo')}: <Badge bg="info">{t('app.FastCost')}</Badge>
                        </Card.Body>
                    </Card>
                </Col>
            </Form>
        )}        
    </Formik> 
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen='md-down'
        size="lg"
    >
        <Modal.Body>
          {t('error.'+err)}
          <Button size='sm' variant="outline-primary" onClick={handleClose}>{t('app.Close')}</Button>
        </Modal.Body>                    
    </Modal>
    </>   
  );
}

export default RegisterFast;