import { useTranslation } from 'react-i18next';
import React, { useState, useRef, useCallback, setState, useEffect, useContext  } from "react";
import Container from 'react-bootstrap/esm/Container';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/esm/Row';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from 'react-bootstrap/esm/Col';
import Button from 'react-bootstrap/esm/Button';
import Image from 'react-bootstrap/esm/Image';
import { EmojiAngry, EmojiSmile, XCircleFill, PlusCircleFill} from "react-bootstrap-icons";
import StarRatings from 'react-star-ratings';
import uuid from 'react-uuid';

function QuestionConstructor(props) {
    const { t } = useTranslation();
    const [QuestionsObject, setQuestionsObject] = useState(props.question);
    const inputFile = useRef(null);
    const [maxRank, setMaxRank] = useState(10);
    const [maxRate, setMaxRate] = useState(5);
    const [listOfRadios, setListOfRadios] = useState([]);
    const [listOfChecks, setListOfChecks] = useState([]);
    
    useEffect(() => {
        const strObj = JSON.parse(props.question.jsonobject===""?null:props.question.jsonobject);
        switch(props.question.QuestionTypeId){
        case 2:{        
                if (strObj!==null)
                    setListOfRadios(strObj.data);
                    break;
                }                            
        case 3:{        
                if (strObj!==null)
                    setListOfChecks(strObj.data);
                break;
                }
        case 5:{
                    if (strObj!==null)        
                        setMaxRank(strObj.data);
                        break;
                    }                    
        case 6:{
                if (strObj!==null)        
                    setMaxRate(strObj.data);
                    break;
                }                    
            }
    }, []);

    const saveData = () => {
        let strJson = "";
        let QuestionTypeId = 1;
        let pic = QuestionsObject.pic;
        console.log(QuestionsObject);
        switch(QuestionsObject.QuestionTypeId){
            case "1":{        
                    QuestionTypeId = 1;
                    pic = '';
                    break;
                };
            case 1:{        
                    QuestionTypeId = 1;
                    pic = '';
                    break;
                };
            case 2:{        
                    strJson = '{"data":' + JSON.stringify(listOfRadios) + '}';
                    QuestionTypeId = 2;
                    pic = '';
                    break;
                };
            case "2":{        
                    strJson = '{"data":' + JSON.stringify(listOfRadios) + '}';
                    QuestionTypeId = 2;
                    pic = '';
                    break;
                };
            case 3:{        
                    strJson = '{"data":' + JSON.stringify(listOfChecks) + '}';
                    QuestionTypeId = 3;
                    pic = '';
                    break;
                };
            case "3":{        
                    strJson = '{"data":' + JSON.stringify(listOfChecks) + '}';
                    QuestionTypeId = 3;
                    pic = '';
                    break;
                };
            case "4":{        
                    QuestionTypeId = 4;
                    break;
                };
            case 4:{        
                    QuestionTypeId = 4;
                    break;
                };
            case 5:{        
                    strJson = '{"data":' + maxRank + '}';
                    QuestionTypeId = 5;
                    pic = '';
                    break;
                };
            case "5":{        
                    strJson = '{"data":' + maxRank + '}';
                    QuestionTypeId = 5;
                    pic = '';
                    break;
                };
            case 6:{        
                    strJson = '{"data":' + maxRate + '}';
                    QuestionTypeId = 6;
                    pic = '';
                    break;
                };
            case "6":{        
                    strJson = '{"data":' + maxRate + '}';
                    QuestionTypeId = 6;
                    pic = '';
                    break;
                };
            };
            console.log(strJson);
        const data = {id:QuestionsObject.id,
            PollId:QuestionsObject.PollId,
            question:QuestionsObject.question,
            num:QuestionsObject.num,
            QuestionTypeId:QuestionTypeId,
            jsonobject:strJson,
            pic:pic,
            metricvalue: QuestionsObject.metricvalue};
        props.onUpdateQuestion(data);
    };

    const delCheckList = (id) => {
        setListOfChecks(listOfChecks.filter(obj => obj.id !== id));
    };

    const editCheckList = useCallback((id, value, mode) => {
        setListOfChecks(listOfChecks.map((obj, index) => {
            return obj.id !== id ? obj : mode==='value'?{ id:obj.id, value: value, metricvalue: obj.metricvalue }:{ id:obj.id, value: obj.value, metricvalue: value }
        }))
      }, [listOfChecks])

      const delRadioList = (id) => {
        setListOfRadios(listOfRadios.filter(obj => obj.id !== id));
    };

    const editRadioList = useCallback((id, value, mode) => {
        setListOfRadios(listOfRadios.map((obj, index) => {
          return obj.id !== id ? obj : mode==='value'?{ id:obj.id, value: value, metricvalue: obj.metricvalue }:{ id:obj.id, value: obj.value, metricvalue: value }
        }))
      }, [listOfRadios])

    
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const fileSizeKiloBytes = file.size;
        if(fileSizeKiloBytes > 100000){
            alert("File size is greater than maximum limit 500Kb");
            return
          }
        if (file){
            const base64 = await convertToBase64(file);        
            setQuestionsObject({...QuestionsObject, pic: base64});
        }        
      };

    {if(props.questionStatus === "Del"){
        return (
            <Container>                                      
                <Row>
                    <h2>{t('question.dialogdel')}</h2>
                </Row>
                <Row>
                    <Col sm={9} ></Col>
                    <Col sm={3} size='sm' className='text-end'>
                        <Button size='sm' variant="primary" onClick={()=>{props.onUpdateQuestion(QuestionsObject)}}>{t('app.Yes')}</Button>{'  '}
                        <Button size='sm' variant="outline-primary" onClick={()=>{props.onUpdateQuestion(null)}}>{t('app.No')}</Button>
                    </Col>                
                </Row>
            </Container>
            )
    }else{
        return (
            <Container>                                      
                <Row>
                    <h2>{props.questionStatus === "Add"? t('app.Adding'): t('app.Editing')}</h2>
                </Row>
                <Row>
                    <Col sm={5}>
                        <Row>
                            <InputGroup className="mb-3" size='sm'>
                                <InputGroup.Text>{t('poll.questiontype')}</InputGroup.Text>
                                <Form.Select value={QuestionsObject.QuestionTypeId}
                                    onChange={(e) => { setQuestionsObject({ ...QuestionsObject, QuestionTypeId: e.target.value})}}>
                                    <option value="1">{t('poll.texttype')}</option>
                                    <option value="2">{t('poll.radiotype')}</option>
                                    <option value="3">{t('poll.checktype')}</option>
                                    <option value="4">{t('poll.imagetype')}</option>
                                    <option value="5">{t('poll.ranktype')}</option>
                                    <option value="6">{t('poll.ratetype')}</option>
                                </Form.Select>                        
                            </InputGroup>
                        </Row>
                        <Row>
                            <InputGroup className="mb-3" size='sm' style={{minHeight:'150px'}}>
                                <InputGroup.Text>{t('poll.question')}</InputGroup.Text>
                                <Form.Control as="textarea" value={QuestionsObject.question} onChange={(e) => { setQuestionsObject({ ...QuestionsObject, question: e.target.value})}}/>                        
                            </InputGroup>
                        </Row>
                    </Col>
                    <Col sm={7}>
                        {
                            {
                                1: <>{t('question.typetextdescription')}
                                    <InputGroup className="mb-3" size='sm' style={{marginTop: '15px', width:'130px'}}>
                                        <InputGroup.Text>{t('question.metric')}</InputGroup.Text>
                                        <Form.Control value={QuestionsObject.metricvalue} 
                                            onChange={(e) => {
                                                if(+e.target.value){
                                                    setQuestionsObject({...QuestionsObject, metricvalue:e.target.value})
                                                }else{setQuestionsObject({...QuestionsObject, metricvalue:''})}
                                            }
                                        }/>
                                    </InputGroup></>,
                                2: <>
                                        {t('question.typeradiodescription')}
                                        <Row><Col sm={9}></Col><Col sm={3} className='text-end'><Button size='sm' variant='outline-primary' style={{marginTop:'10px'}}
                                            onClick={(e) => { setListOfRadios([...listOfRadios, {id: uuid(), value: ''}])}}>{t('app.Add')}{'  '}<PlusCircleFill size={14} color='green'/></Button></Col></Row>
                                        {listOfRadios.map((value, key) => {
                                            return(<Row key={'rch' + key}>
                                                <Col sm={8}>
                                                    <InputGroup key={'ig' + key} style={{marginTop: '15px'}} size='sm'>
                                                        <InputGroup.Radio key={'i' + key} checked size='sm' disabled/>
                                                        <Form.Control key={key} id={value.id} size='sm' value={value.value} onChange={(e) => {editRadioList(e.target.id, e.target.value, 'value')}}/>
                                                        <Button variant="outline-primary" onClick={() => {delRadioList(value.id)}}><XCircleFill color='red'/></Button>                                                    
                                                    </InputGroup>
                                                </Col>
                                                <Col sm={4}>
                                                <InputGroup key={'igm' + key} style={{marginTop: '15px'}} size='sm'>
                                                    <InputGroup.Text>{t('question.metric')}</InputGroup.Text>
                                                    <Form.Control key={'m' + key} id={value.id} value={value.metricvalue} 
                                                        onChange={(e) => {
                                                            if(+e.target.value){
                                                                editRadioList(e.target.id, e.target.value, 'metricvalue');
                                                            }else{editRadioList(e.target.id, '', 'metricvalue');}
                                                        }}/>                                                    
                                                </InputGroup>
                                                </Col>
                                                </Row>
                                                ) 
                                            }
                                        )}
                                    </>,
                                3: <>
                                        {t('question.typecheckdescription')}
                                        <Row><Col sm={9}></Col><Col sm={3} className='text-end'><Button size='sm' variant='outline-primary' style={{marginTop:'10px'}}
                                            onClick={(e) => { setListOfChecks([...listOfChecks, {id: uuid(), value: ''}])}}>{t('app.Add')}{'  '}<PlusCircleFill size={14} color='green'/></Button></Col></Row>
                                        {listOfChecks.map((value, key) => {
                                            return(<Row key={'ch' + key}>
                                                <Col sm={8}>
                                                    <InputGroup key={'ig' + key} style={{marginTop: '15px'}} size='sm'>
                                                        <InputGroup.Checkbox key={'i' + key} checked size='sm' disabled/>
                                                        <Form.Control key={key} id={value.id} size='sm' value={value.value} onChange={(e) => {editCheckList(e.target.id, e.target.value, 'value')}}/>
                                                        <Button variant="outline-primary" onClick={() => {delCheckList(value.id)}}><XCircleFill color='red'/></Button>                                                    
                                                    </InputGroup>
                                                    </Col>
                                                <Col sm={4}>
                                                <InputGroup key={'igm' + key} style={{marginTop: '15px'}} size='sm'>
                                                    <InputGroup.Text>{t('question.metric')}</InputGroup.Text>
                                                    <Form.Control key={'m' + key} id={value.id} value={value.metricvalue} 
                                                        onChange={(e) => {
                                                            if(+e.target.value){
                                                                editCheckList(e.target.id, e.target.value, 'metricvalue');
                                                            }else{editCheckList(e.target.id, '', 'metricvalue');}
                                                        }}/>                                                    
                                                </InputGroup>
                                                </Col>
                                                </Row>
                                                ) 
                                            }
                                        )}
                                    </>,
                                4: <>
                                        {t('question.typeimagedescription')}
                                        <InputGroup className="mb-3" size='sm' style={{marginTop: '15px', width:'130px'}}>
                                            <InputGroup.Text>{t('question.metric')}</InputGroup.Text>
                                            <Form.Control value={QuestionsObject.metricvalue} onChange={(e) => {
                                                    if(+e.target.value){
                                                        setQuestionsObject({...QuestionsObject, metricvalue:e.target.value})
                                                    }else{setQuestionsObject({...QuestionsObject, metricvalue:''})}
                                                }
                                            }/>
                                        </InputGroup>
                                        <input id="myInput"
                                                type="file"
                                                accept=".jpeg, .png, .jpg"
                                                ref={inputFile}
                                                style={{display: 'none'}}
                                                onChange={(e) => handleFileUpload(e)}/>
                                            <Image className="border shadow mt-4" role="button" alt={t('app.ClickToChoose')} src={`${QuestionsObject.pic}`} width={128} height={128} onClick={()=>{inputFile.current.click()}}/>
                                    </>,
                                5: <>
                                        <Row>{t('question.typerangdescription')}</Row>
                                        <Row>
                                            <Col sm={6}>
                                                <InputGroup className="mb-3" size='sm' style={{marginTop: '15px'}}>
                                                    <InputGroup.Text>{t('question.max')}</InputGroup.Text>
                                                    <Form.Control value={maxRank} onChange={(e) => {
                                                        if(+e.target.value){
                                                            setMaxRank(Math.min(Math.max(e.target.value, 0), 100))
                                                        }else{setMaxRank(10)}
                                                        
                                                        }}/>
                                                </InputGroup>
                                                <InputGroup className="mb-3" size='sm' style={{marginTop: '15px', width:'130px'}}>
                                                    <InputGroup.Text>{t('question.metric')}</InputGroup.Text>
                                                    <Form.Control value={QuestionsObject.metricvalue} onChange={(e) => {
                                                            if(+e.target.value){
                                                                setQuestionsObject({...QuestionsObject, metricvalue:e.target.value})
                                                            }else{setQuestionsObject({...QuestionsObject, metricvalue:''})}
                                                        }
                                                    }/> 
                                                </InputGroup>
                                            </Col>                                            
                                        </Row>
                                        <Row><Col sm={1}><EmojiAngry color='red' size={28}/></Col><Col  sm={10}><Form.Range max={10} defaultValue={8}/></Col><Col sm={1}><EmojiSmile color='green' size={28}/></Col></Row>
                                    </>,
                                6: <>
                                        <Row>{t('question.typeratescription')}</Row>
                                        <Row>
                                            <Col sm={4}>
                                                <InputGroup className="mb-3" size='sm' style={{marginTop: '15px'}}>
                                                    <InputGroup.Text>{t('question.count')}</InputGroup.Text>
                                                    <Form.Control value={maxRate} onChange={(e) => {
                                                            if(+e.target.value){
                                                                setMaxRate(Math.min(Math.max(e.target.value, 0), 10))
                                                            }else{setMaxRate(5)}
                                                        }
                                                        }/> 
                                                </InputGroup>
                                                <InputGroup className="mb-3" size='sm' style={{marginTop: '15px', width:'130px'}}>
                                                    <InputGroup.Text>{t('question.metric')}</InputGroup.Text>
                                                    <Form.Control value={QuestionsObject.metricvalue} onChange={(e) => {
                                                            if(+e.target.value){
                                                                setQuestionsObject({...QuestionsObject, metricvalue:e.target.value})
                                                            }else{setQuestionsObject({...QuestionsObject, metricvalue:''})}
                                                        }
                                                    }/> 
                                                </InputGroup>
                                            </Col>                                            
                                        </Row>
                                        <Row><StarRatings
                                                rating={3}
                                                starRatedColor="blue"
                                                numberOfStars={maxRate}
                                                starDimension='25px'
                                                name='rating'
                                                /></Row>
                                    </>
                            }[QuestionsObject.QuestionTypeId]
                        }
                    </Col>
                </Row>
                <Row style={{marginTop: '25px'}}>
                    <Col sm={9} ></Col>
                    <Col sm={3} size='sm' className='text-end'>
                        <Button size='sm' className="shadow" variant="primary" onClick={()=>{saveData()}}>{t('app.Save')}</Button>{'  '}
                        <Button size='sm' variant="outline-primary" onClick={()=>{props.onUpdateQuestion(null)}}>{t('app.Close')}</Button>
                    </Col>                
                </Row>
            </Container>
        )
    }};
}

export default QuestionConstructor;