import React, { useState, useEffect } from "react";
import axios from "axios";
import Menu from "../components/Menu";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ListGroup from "react-bootstrap/esm/ListGroup";
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import { CaretRightFill, SkipForwardFill, SkipBackwardFill, CaretLeftFill, HouseAdd } from "react-bootstrap-icons";
import { Chart as ChartJS, CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'

function Metrics() {
    ChartJS.register(CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Filler,
        Legend);

    const { t } = useTranslation();
    const [listOfMetrics, setListOfMetrics] = useState([]);
    const [companyId, setCompanyId] = useState(0);
    const [metriccount, setmetriccount] = useState(0);
    const [metricsearch, setmetricsearch] = useState('');
    const [metricpagenum, setmetricpagenum] = useState(1);

    const [metricObject, setmetricObject] = useState({
        id:0,
        CompanyId:0,
        name:""    
    });

    const [metricObjectLoad, setmetricObjectLoad] = useState({
        id:0,
        CompanyId:0,
        name:""        
    });
    
    const optionAreaChart={
        responsive: true,
        plugins: {
          legend: {position: 'bottom'},
          title: {
            display: true,
            text: t('chart.metricvalueperpollperweek'),
          },
        },
      }
  
    const [dataAreaChart, setDataAreaChart] = useState({
    labels: ['1', '2', '3', '4', '5', '6', '7'],
    datasets: [
        {
        fill: true,
        label: 'no data',
        data: [0,0,0,0,0,0,0]
        }
    ],
    });
    
    const savemetric = async () => {
        if(metricObjectLoad.id===0){
            await axios.get(SERVER_URL+"/user/auth", {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((response) => {
                if (response.data.error) {
                  alert(response.data.error);
                } else {
                    axios.post(
                        SERVER_URL+"/metric",
                        {
                            name: metricObject.name,
                            CompanyId: response.data.companyid
                        },
                        {
                          headers: { accessToken: localStorage.getItem("accessToken") },
                        }
                      ).then((response) => {
                        if (response.data.error) {
                            alert(response.data.error);
                          } else {
                            setmetricObjectLoad(response.data);
                            setmetricObject(response.data);
                            setListOfMetrics([...listOfMetrics, response.data]);
                          }
                      });
                }
              });            
        }else{
            await axios.put(
                SERVER_URL+"/metric",
                {
                    name: metricObject.name,
                    id: metricObject.id
                },
                {
                  headers: { accessToken: localStorage.getItem("accessToken") },
                }
              ).then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                  } else {
                    setmetricObjectLoad(metricObject);
                    listOfMetrics.find(obj => {
                        if(obj.id === metricObject.id){
                            obj.name = metricObject.name;
                        }
                        return null;
                    })
                  }
              }
    
              );
        }        
    };

    const getmetricList = async (num, searchvar, companyIdvar) => {
        const extrafiltr = searchvar.length>0?searchvar:'all';
        try {
            axios.get(SERVER_URL+"/metric/partly/"+companyIdvar + "/" + num + "/" + extrafiltr + "/").then((response) => {
                setListOfMetrics(response.data);
                if (response.data.length > 0){
                    setmetricObject(response.data[0]);
                    setmetricObjectLoad(response.data[0]);                    
                }                
            });
            axios.get(SERVER_URL+"/metric/count/"+companyIdvar).then((response) => {
                if (!response.error)
                    setmetriccount(response.data);
            });
        } catch (error) {
            alert(error)
        }
    }

    useEffect(() => {
        axios.get(SERVER_URL+"/user/auth", {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.error) {
              alert(response.data.error);
            } else {
                const companyid = response.data.companyid;
                setCompanyId(companyid);
                axios.get(SERVER_URL+"/metric/count/"+companyid).then((resp) => {
                    if (!resp.error)
                        setmetriccount(resp.data);
                });
                axios.get(SERVER_URL+"/metric/partly/"+companyid + "/1/all").then((response) => {
                    setListOfMetrics(response.data);
                    if (response.data.length > 0){
                        setmetricObject(response.data[0]);
                        setmetricObjectLoad(response.data[0]);                        
                        axios.get(SERVER_URL+"/report/10003/"+companyid+"/"+response.data[0].id).then((resp) => {
                            setDataAreaChart(resp.data);
                          });
                    }

                });            
            }
          });        
      }, []);

    const getmetric = async (id) =>{
        axios.get(SERVER_URL+"/metric/byid/"+id).then((response) => {
            if (!response.error){
                setmetricObject(response.data);
                setmetricObjectLoad(response.data);
                axios.get(SERVER_URL+"/report/10003/"+companyId+"/"+response.data.id).then((resp) => {
                    setDataAreaChart(resp.data);
                  });
            }

        });
    };

    const addmetric = () => {
        setmetricObject({id:0,
            companyid:0,
            name:""
            });
        setmetricObjectLoad({id:0,
            companyid:0,
            name:""
            });        
    }

    return (
        <>
            <Menu ActiveKey="/Metrics"/>
            <Container fluid>
                <Row style={{marginTop:'15px'}}>
                    <Col sm={3} className="text-center"  style={{minWidth:'280px'}}>
                        <Row style={{margin:'3px'}} className="justify-content-center">
                            <Button size="lg" variant="primary" className="shadow" onClick={() => {addmetric()}}>
                                {t('metric.add')}{'  '}<HouseAdd/>
                            </Button>
                        </Row>
                        <Row style={{margin:'3px'}} className="mt-2">
                            <Form.Control placeholder={t('app.Search')} value={metricsearch} onChange={(e)=>{setmetricsearch(e.target.value);getmetricList(metricpagenum, e.target.value, companyId)}}/>                            
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                            <ListGroup as="ol" role="button" style={{maxHeight: '800px'}} className="overflow-y-auto">
                                {listOfMetrics.map((value, key) => {
                                    return(
                                            <ListGroup.Item key={key} action as="li" id={value.id} className="shadow" variant={value.id===metricObjectLoad.id? "primary": "outline-primary"}
                                                onClick={() =>{getmetric(value.id)}}>
                                                    <Row>
                                                        <Col xs={12} sm={12} className="text-start">
                                                            <Row>
                                                                <div className="col-11 text-truncate fw-bolder">
                                                                    {value.name}
                                                                </div>
                                                            </Row>                                                                                                            
                                                        </Col>                                                        
                                                    </Row>
                                            </ListGroup.Item>
                                    ) 
                                    }
                                )}                            
                            </ListGroup>
                        </Row>
                        <Row className="mt-4">
                            <ButtonToolbar className="justify-content-center"  style={{marginBottom:'10px'}}>
                                <ButtonGroup className="text-center shadow" size="sm">
                                    <Button variant="outline-primary" onClick={()=>{if(metricpagenum>1){setmetricpagenum(1); getmetricList(1, metricsearch, companyId)}}}><SkipBackwardFill/></Button>
                                    <Button variant="outline-primary" onClick={()=>{if(metricpagenum>1){setmetricpagenum(metricpagenum-1); getmetricList(metricpagenum-1, metricsearch, companyId)}}}><CaretLeftFill/></Button>
                                    <Button variant="primary" disabled>{metricpagenum} {'  '} {t('app.From')} {'  '} {Math.ceil(metriccount/10)}</Button>
                                    <Button variant="outline-primary" onClick={()=>{if(metricpagenum*10<metriccount){setmetricpagenum(metricpagenum+1); getmetricList(metricpagenum+1, metricsearch, companyId)}}}><CaretRightFill/></Button>
                                    <Button variant="outline-primary" onClick={()=>{if(metricpagenum<Math.ceil(metriccount/10)){setmetricpagenum(Math.ceil(metriccount/10)); getmetricList(Math.ceil(metriccount/10), metricsearch, companyId)}}}><SkipForwardFill/></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Row>
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <Col sm={8}><h1>{t('metric.metric')} ({metricObjectLoad.id===0 ? t('app.create') : t('app.edit')}*)</h1></Col>
                            <Col sm={4} className="text-end" style={{marginTop:'2px'}}>
                                <Button variant={metricObject.name===metricObjectLoad.name? "outline-primary": "primary"} size="lg"
                                    onClick={() => {savemetric()}}>
                                {t('app.Save')}
                                </Button>{' '}                                
                            </Col>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('app.name')}</Form.Label>
                                    <Col sm="10">
                                        <Form.Control style={{fontWeight: "bolder"}} value={metricObject.name} onChange={(e) => { setmetricObject({ ...metricObject, name: e.target.value})}}/>
                                    </Col>                                    
                            </Form.Group>                            
                        </Row>
                        <Row className="mt-4">
                            <Line options={optionAreaChart} data={dataAreaChart} />
                        </Row>                        
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Metrics;