import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';

function MoreMetric() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    return (
        <Container fluid style={{background: 'linear-gradient(#03369c,#ffffff)'}}>
            <Row style={{marginTop:'20px'}}>
                    <Col sm={1} xs={12}></Col>
                    <Col sm={8}  xs={12} className='mt-3 text-start' style={{color:'white'}}>
                        <span style={{marginLeft:'10px',fontSize:'3em', fontWeight:'bolder', color:'#FFB100'}} role='button' onClick={()=>{navigate('/')}}>{t('home.title')}</span><br/>
                        <span style={{marginLeft:'10px'}}>{t('home.text')}</span><br/>                                                
                    </Col>
                    <Col sm={2}  xs={12} className='mt-3 d-flex justify-content-center' style={{minWidth:'200px'}}>
                        <Image src="../logo.png" style={{marginLeft:'38px', width:'128px', height:'128px'}} role='button' onClick={()=>{navigate('/')}}/>                          
                    </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <h1 style={{color:'white', marginTop:'15px'}}>{t('moreMetric.core')}</h1>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Row style={{maxWidth:'1200px', marginBottom:'100px'}}>
                    <Card>
                        <Card.Body>
                            Метрика - це система оцінки опитування. В залежності від виду опитування це може бути значення рейтингу, оцінка знань, кількісна оцінка заповнення форми, тощо.
                            Метрика - є числове значення відповіді. Для прикладу у питаннях з варіантами відповіді можна вказати для кожного варіанта своє значення, де сума 
                            всіх значень буде загальною оцінкою питання, що може бути корисним при тестуваннях. Вказавши значення метрики для питань з рейтингом, можемо отримувати загальний 
                            рейтинг підрозділу. Для кожного опитування можна вказати свою систему оцінкии із довідника "Метрики". 
                        </Card.Body>
                    </Card>
                </Row> 
            </Row> 
            <Row  className="fixed-bottom"  style={{height:'65px', backgroundColor:'#03369c'}}>
                <Col className="text-center mt-2">
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyEMail')}</span><br/>
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyPhone')}</span><br/>
                </Col>                
            </Row>
        </Container>        
    );
}

export default MoreMetric;