import { useTranslation } from 'react-i18next'
import { useParams } from "react-router-dom";
import axios from "axios";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/esm/Container';
import { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Badge from 'react-bootstrap/esm/Badge';
import Form from 'react-bootstrap/Form';
import Placeholder from 'react-bootstrap/Placeholder';
import InputGroup from 'react-bootstrap/InputGroup';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/esm/Image';

import { SERVER_URL } from '../helpers/constants'

function ResponseAdmin() {
    const ref = useRef(null);
    const { t } = useTranslation();
    const { responseId } = useParams();
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const [accessecode, setAccessecode] = useState('');
    const responseStatus = [t('response.statuswait'), t('response.statusnew'), t('response.statusdone'), t('response.statusspam')];
    const responseStatusColor = ["primary", "secondary", "success", "danger"];
    const [responseObject, setResponseObject] = useState({
        id:0,
        responsename:'',
        email:'',
        phone:'',
        ResponseStatusId:0,
        name:'',
        createdAt:null,
        updatedAt:null
    });
    const [listOfAnswers, setListOfAnswers] = useState([{num:-1},{num:-1},{num:-1}]);
    const [metricValue, setMetricValue] = useState(0);
    const [validated, setValidated] = useState(true);
    const [msg, setMsg] = useState('');

    useEffect(()=>{
       
        }, []);

    const codeSubmit = () => {
        if(accessecode!==''){
            axios.get(SERVER_URL+"/pollresponse/byadmincode/"+responseId+"/"+accessecode).then((resp) => {
                if (resp.data!==null){
                    setResponseObject(resp.data);
                    axios.get(SERVER_URL+"/responseanswer/"+resp.data.id).then((resp) => {
                        if (resp.data!==null)
                            {setListOfAnswers(resp.data);
                                var metricsum = 0;
                                resp.data.map((el)=>{
                                    metricsum+=el.metricvalue;
                                }
                                )
                                setMetricValue(metricsum);
                            }
                        });                            
                    setValidated(true);
                    setShow(false);            
                }else{setValidated(false);}                
        });
    }else{setValidated(false);}
    };

    const saveStatus = (status) => {
        axios.put(
            SERVER_URL+"/pollresponse/status/",
            {
                uuid: responseId,
                ResponseStatusId: status,
                code: accessecode
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                      }
                  });                
    }

    const saveAnswer = () => {
        axios.post(
            SERVER_URL+"/responseanswer/admin/" + accessecode + "/",
            {
                PollResponseId: responseObject.id,
                QuestionTypeId: 1,
                code: accessecode,
                jsonquestion: msg,
                num: listOfAnswers.length+1
            }
          ).then((response) => {
            if (response.data.error) {
                setMsg('');
                alert(response.data.error);
              } else {
                    setListOfAnswers([...listOfAnswers, response.data]);
                    setMsg('');
                    if(responseObject.ResponseStatusId!==4){
                        saveStatus(3)
                        setResponseObject({...responseObject, ResponseStatusId: 3})
                    }
                    const lastChildElement = ref.current?.lastElementChild;
                    lastChildElement?.scrollIntoView({behavior: 'smooth'});
                      }                    
                  });                
    }

    return (
        <Container style={{maxWidth:'600px'}}>
            <Card className="shadow" bg='warning' style={{marginBottom:'5px'}}>
                <Card.Body>
                    <Card.Title>
                        <Row>
                            <Col sm={6} xs={6}>
                                {t('response.titleadmin')}
                            </Col>
                            <Col sm={6} xs={6}>
                                <Button onClick={()=>{setResponseObject({...responseObject, ResponseStatusId: 3}); saveStatus(3)}} size='sm'>
                                        {t('response.confirmdone')}
                                </Button>
                            </Col>                                                
                        </Row>                        
                    </Card.Title>
                    <Row>
                        <Col sm={6}>{t('poll.num')}{'  '}{responseObject.id}{'  '}{t('response.datefrom')}{'  '}{responseObject.createdAt}</Col>
                        <Col sm={6}>
                            {t('response.status')}:{'  '}
                            <Badge bg={responseStatusColor[responseObject.ResponseStatusId-1]} size="lg">
                                {responseStatus[responseObject.ResponseStatusId-1]}
                            </Badge>{'  '}                                                        
                        </Col>
                    </Row>
                    <Row>
                        
                    </Row>
                    <Row>
                        <Col sm={6} style={{marginTop:'10px'}}>
                            <Form.Check
                                type="switch"
                                label={t('response.addtospam')}
                                checked={(responseObject.ResponseStatusId===4)?true:false}
                                onChange={(e)=>{if(e.target.checked===false){setResponseObject({...responseObject, ResponseStatusId: 1});saveStatus(1)}else{setResponseObject({...responseObject, ResponseStatusId: 4});saveStatus(4)}}}
                            />                        
                        </Col>                        
                        <Col sm={4} style={{marginTop:'10px'}}>
                            {t('metric.metric')}<Badge bg='success'>{metricValue}</Badge>
                        </Col>
                    </Row>                    
                </Card.Body>
            </Card>
                    {listOfAnswers.map((value, key) => {
                        if(value.num===-1){
                            return(
                                <Container key={key} fluid>
                                    <Row style={{marginBottom:'5px'}}>
                                        <Col sm={7} xs={10}>
                                            <Card>
                                                <Card.Body>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder xs={7} /> <Placeholder xs={4} />
                                                        <Placeholder xs={6} />
                                                    </Placeholder>
                                                </Card.Body>
                                            </Card>                                            
                                        </Col>
                                        <Col sm={5} xs={2}></Col>
                                    </Row>
                                    <Row style={{marginBottom:'5px'}}>
                                        <Col sm={5} xs={2}></Col>
                                        <Col sm={7} xs={10}>
                                            <Card style={{backgroundColor:'#cfe2ff'}}>
                                                <Card.Body>
                                                    <Placeholder as={Card.Text} animation="glow">
                                                        <Placeholder xs={7} /> <Placeholder xs={4} />
                                                        <Placeholder xs={6} />
                                                    </Placeholder>
                                                </Card.Body>
                                            </Card>                                            
                                        </Col>                                    
                                    </Row>                                    
                                </Container>                                    
                                )                                                             
                        }else{
                            return(
                                <Container key={key} fluid ref={ref}>
                                    {value.jsonquestion===''||value.jsonquestion===undefined||value.jsonquestion===null ? 
                                    (
                                        <></>
                                    ) : (
                                        <Row style={{marginBottom:'5px'}}>
                                            <Col sm={7} xs={10}>
                                                <Card className='shadow'>
                                                    <Card.Body className='fw-bolder'>
                                                        {value.jsonquestion}                                                    
                                                    </Card.Body>
                                                    {value.picquestion===''||value.picquestion===undefined||value.picquestion===null ? (
                                                        <></>
                                                    ) : (
                                                        <Image src={`${value.picquestion}`} width={64} height={64}/>
                                                    )}
                                                    <Row style={{fontSize:'8px', justifyContent:'end', marginRight:'15px'}}>{value.createdAt}</Row>
                                                </Card>                                            
                                            </Col>
                                            <Col sm={5} xs={2}></Col>
                                        </Row>
                                    )}                              
                                    {value.jsonanswer===''||value.jsonanswer===undefined||value.jsonanswer===null ? 
                                    (
                                        <></>
                                    ) : (
                                        <Row style={{marginBottom:'5px'}}>
                                            <Col sm={5} xs={2}></Col>
                                            <Col sm={7} xs={10}>
                                                <Card style={{backgroundColor:'#cfe2ff'}}>
                                                    <Card.Body className='text-end fw-bolder'>
                                                        {value.jsonanswer}                                                    
                                                    </Card.Body>
                                                    <Row style={{fontSize:'8px'}}>
                                                        <Col sm={6} xs={6} style={{fontSize:'8px', justifyContent:'start'}}>
                                                            <span style={{marginLeft:'15px'}}>{value.createdAt}</span>
                                                        </Col>
                                                        <Col sm={6} xs={6} style={{fontSize:'10px', textAlign:'end'}}>
                                                        <span style={{marginRight:'15px'}}>{t('metric.metric')}{value.metricvalue}</span>
                                                        </Col>
                                                    </Row>
                                                </Card>                                            
                                            </Col>                                    
                                        </Row>      
                                    )}                              
                                </Container>
                                ) 
                            }
                        }                        
                    )}
            <Row style={{marginBottom:'60px'}}>

            </Row>
            <div >
                <br/>
                <br/>
                <br/>
            </div>
            <Row ref={ref}  className="fixed-bottom justify-content-start"  style={{height:'65px', backgroundColor:'#ffc107'}}>
                <Col sm={1} xs={1}></Col>
                <Col sm={10} xs={10} style={{marginTop:'7px'}}>
                    <InputGroup className={responseObject.ResponseStatusId===4?"mb-3 invisible":"mb-3"}>
                        <Form.Control
                            placeholder={t('app.MessageText')}
                            value={msg}
                            onChange={(e)=>{setMsg(e.target.value)}}
                        />
                        <Button variant="primary" onClick={()=>{saveAnswer()}}>
                            {t('app.Send')}
                        </Button>
                    </InputGroup>                
                </Col>
                <Col sm={1} xs={1}></Col>
            </Row>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen='md-down'
                size="sm"
            >
                <Modal.Body style={{backgroundColor:"#ffc107"}}>
                    <Row className="mb-3">
                        <Col sm={2}></Col>
                        <Col sm={8} className='text-center'>
                            <Form.Label>{t('response.accesscode')}</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                value={accessecode}                                    
                                onChange={(e)=>{setAccessecode(e.target.value)}}
                            />
                            <span className={validated?'text-danger fw-bolder d-none':'text-danger fw-bolder'} >{t('response.badaccesscode')}</span>                                                    
                        </Col>
                        <Col sm={2}></Col>                        
                    </Row>
                    <Row>
                        <Col sm={2}></Col>
                        <Col sm={8} className='text-center'><Button onClick={codeSubmit}>OK</Button></Col>
                        <Col sm={2}></Col>
                    </Row>                                            
                </Modal.Body>                    
            </Modal>                
        </Container>        
    );
}

export default ResponseAdmin;