import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Menu from "../components/Menu";
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Card from "react-bootstrap/esm/Card";
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Chart as ChartJS, ArcElement, CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'
import uuid from "react-uuid";

function Main() {
    ChartJS.register(ArcElement, CategoryScale,
      LinearScale,
      PointElement,
      LineElement,
      Title,
      Tooltip,
      Filler,
      Legend);
    
    const { t } = useTranslation();
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const [postImage, setPostImage] = useState('');
    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
    
    const [companyObject, setCompanyObject] = useState({
        id:0,
        name:"",
        email:"",
        logo:"",
        apikey:"",
        PayPlanId: 1,
        payplanexpiration: null
    });

    const [companyObjectLoad, setCompanyObjectLoad] = useState({
        id:0,
        name:"",
        email:"",
        logo:"",
        apikey:"",
        payplanid: 1,
        payplanexpiration: null
    });

    const [branchInfo, setBranchInfo] = useState({
      count:0,
      enable:0,
      disable:0
    });
    
    const [pollInfo, setPollInfo] = useState({
      count:0,
      enable:0,
      disable:0
    });

    const optionDoughnut={
      responsive: true,
      plugins: {
        legend: {position: 'bottom'},
        title: {
          display: true,
          text: t('chart.pollcountperbranch'),
        },
      },
    }

    const [dataDoughnut, setDataDoughnut] = useState({
      labels: ['no data'],
      datasets: [
        {
          data: [1],
          backgroundColor: [
            'rgba(255, 99, 132, 0.2)',            
          ],
          borderColor: [
            'rgba(255, 99, 132, 1)',            
          ],
          borderWidth: 1,
        }
      ],
    });

    const optionAreaChart={
      responsive: true,
      plugins: {
        legend: {position: 'top'},
        title: {
          display: true,
          text: t('chart.pollcountperweek'),
        },
      },
    }

    const [dataAreaChart, setDataAreaChart] = useState({
      labels: ['1', '2', '3', '4', '5', '6', '7'],
      datasets: [
        {
          fill: true,
          label: 'no data',
          data: [0,0,0,0,0,0,0]
        }
      ],
    });

    useEffect(() => {
        axios.get(SERVER_URL+"/user/auth", {
            headers: {
              timeout: 5000,
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.error) {
              //alert(response.data.error);
            } else {
                
                const companyid = response.data.companyid;
                axios.get(SERVER_URL+"/company/"+companyid).then((response) => {
                    setCompanyObject(response.data);
                    setCompanyObjectLoad(response.data);
                    
                    if(response.data.logo!==null){
                        setPostImage(response.data.logo);
                    }
                    axios.get(SERVER_URL+"/branch/countinfo/"+companyid).then((resp) => {
                      setBranchInfo(resp.data);
                    });
                    axios.get(SERVER_URL+"/poll/countinfo/"+companyid).then((resp) => {
                      setPollInfo(resp.data);
                    });
                    axios.get(SERVER_URL+"/report/10001/"+companyid).then((resp) => {
                      setDataAreaChart(resp.data);
                    });
                    axios.get(SERVER_URL+"/report/10002/"+companyid).then((resp) => {
                      setDataDoughnut(resp.data);
                    });
                    setShow(false)
                });            
            }
          }).catch(error => {
            alert(t("error.1007"));      
          });        
      }, []);
      
    const saveName = () => {
      if(companyObject.name!==''&&companyObject.name!==null)
        {axios.put(
            SERVER_URL+"/company/name",
            {
              newName: companyObject.name,
              id: companyObject.id,
            },
            {
              headers: { accessToken: localStorage.getItem("accessToken") },
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                setCompanyObjectLoad(companyObject);
              }
          }

         );}
    };

    const saveEMail = () => {
      if(companyObject.email!==''&&companyObject.email!==null)
        {axios.put(
            SERVER_URL+"/company/email",
            {
              newEmail: companyObject.email,
              id: companyObject.id,
            },
            {
              headers: { accessToken: localStorage.getItem("accessToken") },
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                setCompanyObjectLoad(companyObject);
              }
          }

          );}
    };

  const generateApiKey = () => {
    const newApikey = uuid();
      axios.put(
          SERVER_URL+"/company/apikey",
          {
            newApikey: newApikey,
            id: companyObject.id,
          },
          {
            headers: { accessToken: localStorage.getItem("accessToken") },
          }
        ).then((response) => {
          if (response.data.error) {
              alert(response.data.error);
            } else {
              setCompanyObject({...companyObject, apikey:newApikey});
              setCompanyObjectLoad({...companyObjectLoad, apikey:newApikey});
            }
        }

        );
  };

    const saveLogo = async () => {
      if(companyObject.logo!==''&&companyObject.logo!==null)
        {await axios.put(
            SERVER_URL+"/company/logo",
            {
              newLogo: companyObject.logo,
              id: companyObject.id,
            },
            {
              headers: { accessToken: localStorage.getItem("accessToken") },
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                setCompanyObjectLoad(companyObject);
              }
          }

          );}
    };

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const fileSizeKiloBytes = file.size;
        if(fileSizeKiloBytes > 100000){
            alert("File size is greater than maximum limit 100Kb");
            return
          }
        if (file){
            const base64 = await convertToBase64(file);        
            setPostImage(base64);
            setCompanyObject({ ...companyObject, logo: base64});            
        }        
      };
    const inputFile = useRef(null) 

    return (
        <>
            <Menu ActiveKey="/main"/>            
            <Row>
                <Col sm={2} className="text-center mt-2 mb-2" style={{minWidth:'150px'}}>
                    <input id="myInput"
                        type="file"
                        accept=".jpeg, .png, .jpg"
                        ref={inputFile}
                        style={{display: 'none'}}
                        onChange={(e) => handleFileUpload(e)}/>
                      <Card className="shadow" >
                        <Card.Header className="fw-bold">{t('main.logo')}</Card.Header>
                        <Card.Img style={{cursor:'pointer'}} alt={t('app.ClickToChoose')}  src={`${postImage}`} onClick={()=>{inputFile.current.click()}}/>
                        <Card.Body>
                          <Button className="mt-3" type="submit" variant={companyObject.logo===companyObjectLoad.logo? "outline-primary": "primary"} onClick={() => {saveLogo()}}>
                              {t('app.Save')}
                          </Button>                                        
                        </Card.Body>                        
                      </Card>                    
                </Col>
                <Col sm={10} className="mt-2">
                    <Card className="shadow mb-2" >
                      <Card.Header className="fw-bold">{t('main.params')}</Card.Header>
                      <Card.Body>
                          <Form.Label>{t('main.companyname')}</Form.Label>
                          <InputGroup className="mb-3">
                              <Form.Control
                                  style={{fontWeight: "bolder"}}
                                  value={companyObject.name}
                                  onChange={(e) => { setCompanyObject({ ...companyObject, name: e.target.value})}}                            
                              />
                              <Button variant={companyObject.name===companyObjectLoad.name? "outline-primary": "primary"} onClick={() => {saveName()}}>
                                  {t('app.Save')}
                              </Button>
                            </InputGroup>
                            <Form.Label>{t('main.companyemail')}</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="EMail"
                                    value={companyObject.email}
                                    onChange={(e) => { setCompanyObject({ ...companyObject, email: e.target.value})}}
                                />
                                <Button variant={companyObject.email===companyObjectLoad.email? "outline-primary": "primary"} onClick={() => {saveEMail()}}>
                                    {t('app.Save')}
                                </Button>
                            </InputGroup>                                        
                            <Form.Label>{t('main.apikey')}</Form.Label>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    value={companyObject.apikey}
                                    readOnly
                                />
                                <Button variant="outline-primary" onClick={() => {generateApiKey()}}>
                                    {t('main.apikeygenerate')}
                                </Button>
                            </InputGroup>                                        
                          </Card.Body>
                          <Card.Footer><h2>{t('app.PayPlan')}: <Badge bg="info">{companyObject.PayPlanId === 1 ? t('app.PayPlanFree') : t('app.PayPlanBase')}</Badge> {companyObject.payplanexpiration ? t('app.PayPlanExpiration') + companyObject.payplanexpiration : t('app.PayPlanExpirationEnd')}</h2></Card.Footer>
                      </Card>
                </Col>                
            </Row>
            <Row>
                <Col sm={2} className="text-center">                    
                    <Card className="shadow">
                        <Card.Header>
                            <Card.Title>{t('app.Branches')}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                              <Col sm={8} xs={8}><Card.Subtitle>{t('app.Active')}:</Card.Subtitle></Col>
                              <Col sm={4} xs={4}><Card.Subtitle><Badge>{branchInfo.enable}</Badge></Card.Subtitle></Col>
                            </Row>
                            <Row className="mt-3">
                              <Col sm={8} xs={8}><Card.Subtitle>{t('app.Block')}:</Card.Subtitle></Col>
                              <Col sm={4} xs={4}><Card.Subtitle><Badge bg="danger">{branchInfo.disable}</Badge></Card.Subtitle></Col>
                            </Row>                                                        
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                              <Col sm={8} xs={8}><Card.Title>{t('app.Total')}:</Card.Title></Col>
                              <Col sm={4} xs={4}><Card.Title><Badge bg={pollInfo.disable===0?"success":"warning"}>{branchInfo.count}</Badge></Card.Title></Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                    <Card className="shadow mt-2">
                        <Card.Header>
                            <Card.Title>{t('app.Polls')}</Card.Title>
                        </Card.Header>
                        <Card.Body>
                            <Row className="mt-3">
                              <Col sm={8} xs={8}><Card.Subtitle>{t('app.On')}:</Card.Subtitle></Col>
                              <Col sm={4} xs={4}><Card.Subtitle><Badge>{pollInfo.enable}</Badge></Card.Subtitle></Col>
                            </Row>
                            <Row className="mt-3">
                              <Col sm={8} xs={8}><Card.Subtitle>{t('app.Off')}:</Card.Subtitle></Col>
                              <Col sm={4} xs={4}><Card.Subtitle><Badge bg="danger">{pollInfo.disable}</Badge></Card.Subtitle></Col>
                            </Row>                                                        
                        </Card.Body>
                        <Card.Footer>
                            <Row>
                              <Col sm={8} xs={8}><Card.Title>{t('app.Total')}:</Card.Title></Col>
                              <Col sm={4} xs={4}><Card.Title><Badge bg={pollInfo.disable===0?"success":"warning"}>{pollInfo.count}</Badge></Card.Title></Col>
                            </Row>
                        </Card.Footer>
                    </Card>
                </Col>
                <Col sm={6} className="text-centre">                  
                  <Line options={optionAreaChart} data={dataAreaChart} />
                </Col>
                <Col sm={4} className="text-centre">
                  <Doughnut options={optionDoughnut} data={dataDoughnut} />
                </Col>                
            </Row>  
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                centered
                fullscreen='md-down'
                size="lg"
            >
                <Modal.Body>
                    <Row>
                      <Col className="text-end">
                        <h1>{t('app.Load')}</h1>
                      </Col>                      
                      <Col className="text-start mt-2">
                        <Spinner animation="border" size="sm" variant="danger"/>
                        <Spinner animation="border"/>
                      </Col>                      
                    </Row>                    
                </Modal.Body>                    
            </Modal>                      
        </>
    );
}

export default Main;