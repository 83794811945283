import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import uuid from 'react-uuid';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import Image from "react-bootstrap/esm/Image";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import Placeholder from 'react-bootstrap/Placeholder';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { Rating } from '@smastrom/react-rating';
import '@smastrom/react-rating/style.css';

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'
import StarRatings from "react-star-ratings";
import Badge from "react-bootstrap/esm/Badge";

function Poll() {
    const { t } = useTranslation();
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [listOfQuestions, setListOfQuestions] = useState([{
        id:0,
        question:"",
        num:0,
        QuestionTypeId:0,
        jsonobject:"",
        pic:"",
        metricvalue: 0,
        metricresult:0,
        answerid:'',
        answervalue: ''
    }]);
    const { pollId, branchId, extId } = useParams();
    const [pageState, setPagetState] = useState('load');
    const [polObject, setPollObject] = useState({
        branchname:"",
        branchenable:0,
        branchaddress:"",
        managerphoto:"",
        showmanagerphoto: 0,
        managername:"",
        description:"",
        finalmessage:"",
        pollid:"",
        pollphoto:"",
        showbranchinfo: 0,
        showphoto: 0,
        companyname:"",
        companylogo:"",
        enable:0,
        startdate:null,
        endlink: "",
        nocompanyname: 0,
        color: "#004A97"
    });
    const [polUUID, setPolUUID] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userName, setUserName] = useState('');
    const [errorText, setErrorText] = useState('');
    
      const SadFace = (
        <path d="M12.0000002,1.99896738 C17.523704,1.99896738 22.0015507,6.47681407 22.0015507,12.0005179 C22.0015507,17.5242217 17.523704,22.0020684 12.0000002,22.0020684 C6.47629639,22.0020684 1.99844971,17.5242217 1.99844971,12.0005179 C1.99844971,6.47681407 6.47629639,1.99896738 12.0000002,1.99896738 Z M12.0000002,3.49896738 C7.30472352,3.49896738 3.49844971,7.30524119 3.49844971,12.0005179 C3.49844971,16.6957946 7.30472352,20.5020684 12.0000002,20.5020684 C16.6952769,20.5020684 20.5015507,16.6957946 20.5015507,12.0005179 C20.5015507,7.30524119 16.6952769,3.49896738 12.0000002,3.49896738 Z M12.0000001,13.4979816 C13.6312483,13.4979816 15.1603686,14.1528953 16.2810488,15.2934358 C16.5713583,15.5888901 16.5671876,16.0637455 16.2717333,16.354055 C15.976279,16.6443646 15.5014236,16.6401939 15.211114,16.3447396 C14.3696444,15.4883577 13.2246935,14.9979816 12.0000001,14.9979816 C10.7726114,14.9979816 9.62535029,15.4905359 8.78347552,16.3502555 C8.49366985,16.6462041 8.01882223,16.6511839 7.72287367,16.3613782 C7.4269251,16.0715726 7.4219453,15.5967249 7.71175097,15.3007764 C8.83296242,14.155799 10.3651558,13.4979816 12.0000001,13.4979816 Z M9.00044779,8.75115873 C9.69041108,8.75115873 10.2497368,9.3104845 10.2497368,10.0004478 C10.2497368,10.6904111 9.69041108,11.2497368 9.00044779,11.2497368 C8.3104845,11.2497368 7.75115873,10.6904111 7.75115873,10.0004478 C7.75115873,9.3104845 8.3104845,8.75115873 9.00044779,8.75115873 Z M15.0004478,8.75115873 C15.6904111,8.75115873 16.2497368,9.3104845 16.2497368,10.0004478 C16.2497368,10.6904111 15.6904111,11.2497368 15.0004478,11.2497368 C14.3104845,11.2497368 13.7511587,10.6904111 13.7511587,10.0004478 C13.7511587,9.3104845 14.3104845,8.75115873 15.0004478,8.75115873 Z" />
      );
      const HappyFace = (
        <>
          <path d="M6.74927 12C6.53852 12 6.33749 12.0887 6.19539 12.2443C6.05329 12.4 5.98323 12.6082 6.00237 12.8181C6.28259 15.8916 8.55224 18.5 12 18.5C15.4478 18.5 17.7174 15.8916 17.9977 12.8181C18.0168 12.6082 17.9468 12.4 17.8047 12.2443C17.6626 12.0887 17.4616 12 17.2508 12H6.74927ZM12 17C9.74286 17 8.12852 15.5205 7.63237 13.5H16.3677C15.8715 15.5205 14.2571 17 12 17Z" />
          <path d="M15.2501 8.75C14.8416 8.75 14.5398 9.03719 14.492 9.35982C14.4314 9.76957 14.05 10.0526 13.6403 9.99192C13.2305 9.93126 12.9475 9.54993 13.0082 9.14018C13.1696 8.0495 14.1313 7.25 15.2501 7.25C16.3689 7.25 17.3306 8.0495 17.492 9.14018C17.5527 9.54993 17.2697 9.93126 16.8599 9.99192C16.4502 10.0526 16.0688 9.76957 16.0082 9.35982C15.9604 9.03719 15.6586 8.75 15.2501 8.75Z" />
          <path d="M7.99202 9.35982C8.03977 9.03719 8.34157 8.75 8.7501 8.75C9.15863 8.75 9.46043 9.03719 9.50818 9.35982C9.56884 9.76957 9.95017 10.0526 10.3599 9.99192C10.7697 9.93126 11.0527 9.54993 10.992 9.14018C10.8306 8.0495 9.86892 7.25 8.7501 7.25C7.63128 7.25 6.66963 8.0495 6.50818 9.14018C6.44753 9.54993 6.73053 9.93126 7.14028 9.99192C7.55003 10.0526 7.93136 9.76957 7.99202 9.35982Z" />
          <path d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2ZM3.5 12C3.5 7.30558 7.30558 3.5 12 3.5C16.6944 3.5 20.5 7.30558 20.5 12C20.5 16.6944 16.6944 20.5 12 20.5C7.30558 20.5 3.5 16.6944 3.5 12Z" />
        </>
      );
      const OneNumber = (
        <>
          <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm12,152a8,8,0,0,1-16,0V98.94434l-11.56348,7.70605a8.00008,8.00008,0,1,1-8.873-13.31445l24-15.99317A8.00039,8.00039,0,0,1,140,84Z"/>
        </>
      )
      const TwoNumber = (
        <>
            <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm24,143.99414a8,8,0,0,1,0,16H104.31738c-.10644.00391-.21289.00684-.31836.00684a8.00343,8.00343,0,0,1-6.30175-12.93164L141.37012,112.794a16.00416,16.00416,0,1,0-28.11621-15.01954A8,8,0,1,1,98.51758,91.542a32.00411,32.00411,0,1,1,56.01269,30.35547c-.07324.1084-.14843.21484-.22754.31934l-34.30566,45.77734Z"/>
        </>
        )
    const ThreeNumber = (
            <>
                <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm21.458,153.45605a35.99982,35.99982,0,0,1-50.91113,0,8.00052,8.00052,0,0,1,11.31445-11.31445A19.99959,19.99959,0,1,0,124.00293,132a8,8,0,0,1-6.55469-12.58691l19.1875-27.4209H103.99707a8,8,0,0,1,0-16h48a8,8,0,0,1,6.55469,12.58691L137.5332,118.61816A36.019,36.019,0,0,1,149.458,177.45605Z"/>
            </>
        )
    const FourNumber = (
            <>
                <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm28,152a8,8,0,0,1-16,0V152H100a7.99995,7.99995,0,0,1-7.54395-10.66211l23.998-68A7.99987,7.99987,0,1,1,131.542,78.66211L111.30664,136H140V112a8,8,0,0,1,16,0Z"/>
            </>
        )
    const FiveNumber = (
            <>
                <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24ZM123.88184,116.002A35.99912,35.99912,0,1,1,98.35449,177.4668a7.99985,7.99985,0,1,1,11.291-11.33594,20.20173,20.20173,0,0,0,28.47461-.001,19.91646,19.91646,0,0,0-.001-28.25976,20.20412,20.20412,0,0,0-28.47461.001,7.99971,7.99971,0,0,1-13.541-6.95214l7.835-48.20215A8.00076,8.00076,0,0,1,111.835,76H152a8,8,0,0,1,0,16H118.63965L114.542,117.21094A36.413,36.413,0,0,1,123.88184,116.002Z"/>
            </>
        )
    const SixNumber = (
            <>
                <path d="M148,144a20,20,0,1,1-20-20A20.02229,20.02229,0,0,1,148,144Zm84-16A104,104,0,1,1,128,24,104.11791,104.11791,0,0,1,232,128Zm-68,16a36.04061,36.04061,0,0,0-36-36q-.917,0-1.82324.04639l16.6914-27.94385a8.00015,8.00015,0,0,0-13.73632-8.20508l-32.249,53.98926c-.11157.18652-.20776.37793-.30224.56982A35.99384,35.99384,0,1,0,164,144Z"/>
            </>
        )
    const SevenNumber = (
            <>
                <path d="M128,24A104,104,0,1,0,232,128,104.11791,104.11791,0,0,0,128,24Zm31.58984,62.53027-32,96a8.0005,8.0005,0,1,1-15.17968-5.06054L140.90039,92H104a8,8,0,0,1,0-16h48a8.00022,8.00022,0,0,1,7.58984,10.53027Z"/>
            </>
        )
    const EightNumber = (
            <>
                <path d="M128,24A104,104,0,1,0,232,128,104.12041,104.12041,0,0,0,128,24Zm24.03125,150.04688a33.99637,33.99637,0,1,1-48.0625-48.09375,30.89828,30.89828,0,0,1,3.10645-2.50782c-.0918-.08984-.20313-.15136-.29395-.24218a29.98907,29.98907,0,0,1,.01563-42.42188c11.29687-11.3125,31.09375-11.32812,42.42187.01563a29.98907,29.98907,0,0,1-.01562,42.42187c-.08594.08594-.19141.14355-.27833.22852a30.83759,30.83759,0,0,1,3.12208,2.52148,33.98762,33.98762,0,0,1-.01563,48.07813Zm-33.9375-62.15625a14,14,0,1,1,19.79688.01562A14.318,14.318,0,0,1,118.09375,111.89063Zm22.64063,25.39062a17.99346,17.99346,0,0,1-.01563,25.45313,18.43509,18.43509,0,0,1-25.45312-.01563,17.99808,17.99808,0,1,1,25.46875-25.4375Z"/>
            </>
        )
    const NineNumber = (
            <>
                <path d="M148,112a20,20,0,1,1-20-20A20.02229,20.02229,0,0,1,148,112Zm84,16A104,104,0,1,1,128,24,104.11791,104.11791,0,0,1,232,128Zm-68-16a36,36,0,1,0-36,36q.91626,0,1.82129-.04639l-16.68945,27.94385a8.00015,8.00015,0,1,0,13.73632,8.20508l32.249-53.99512c.09668-.16162.178-.32812.262-.49365A35.76587,35.76587,0,0,0,164,112Z"/>
            </>
        )

    const customStyles = {
        itemShapes: [OneNumber, TwoNumber, ThreeNumber, FourNumber, FiveNumber, SixNumber, SevenNumber, EightNumber, NineNumber, HappyFace],
        activeFillColor: ['#da1600', '#da1600', '#da1600', '#da1600', '#da1600', '#dcb000', '#dcb000', '#dcb000', '#dcb000', '#009664'],
        inactiveFillColor: '#d5d5d5'
      };

    useEffect(() => {
        axios.get(SERVER_URL+"/poll/startinfo/"+pollId+"/"+branchId+"/"+extId, {mode: 'cors'}).then((response) => {
            if(!response.data.error){
                if(response.data.data.branchenable)
                    {setPollObject(response.data.data);                        
                    axios.get(SERVER_URL+"/pollquestion/bypolluuid/"+pollId, {mode: 'cors'}).then((response) => {
                        if (!response.data.error){
                            setListOfQuestions(response.data);
                            setPagetState('start');                            
                        }else
                            {setErrorText(t('error.2003'));
                            setPagetState('error');};
                    });}
                else{
                    setErrorText(t('error.2003'));
                    setPagetState('error');}
            }else{
                setErrorText(t('error.'+response.data.error));
                setPagetState('error');
            };                
        });                           
      }, []);

    const savePoll = () => {
        setShow(true);
        const struuid = uuid();
        setPolUUID(struuid);
        axios.post(
            SERVER_URL+"/pollresponse",
            {
                uuid: struuid,
                PollId: pollId,
                name:'anonimus',
                branchUuid: branchId,
                extId:extId,
                startdate: polObject.startdate
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                axios.post(
                    SERVER_URL+"/responseanswer/list/" + response.data,
                    {listOfQuestions}
                  ).then((response) => {
                    if (response.data.error) {
                        alert(response.data.error);
                      } else {                        
                        setShow(false);
                        if(extId===''||extId===undefined){
                            setUserEmail('');
                            setUserPhone('');
                            if(polObject.endlink==='')
                                setPagetState('finish');
                            else
                                {setPagetState('access');   
                                window.location.replace(polObject.endlink);}
                        }
                        else{
                            setPagetState('access');                            
                        }
                      }
                  });                
              }
          });
    }   

    const saveInfo = () => {
        axios.put(
            SERVER_URL+"/pollresponse/info/",
            {
                uuid: polUUID,
                email: userEmail,
                phone: userPhone,
                name: userName
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                    setUserEmail('');
                    setUserPhone('');
                    setPagetState('access');                    
                      }
                  });                
    }

    function getRange(itemcount){
        
        return (<Badge>{itemcount}</Badge>)
    }
    
    return(
        <Container fluid style={{backgroundColor: polObject.color}}>
    {{
        'load':            
                <Container style={{backgroundColor: polObject.color}}>
                    <Row>
                        <Col sm={6} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%", backgroundColor:'white'}}>
                                <Card.Body className="text-center" style={{backgroundColor:'white'}}>
                                    Завантаження...
                                <Placeholder as={Card.Subtitle} animation="glow">
                                            <Placeholder xs={6} />
                                        </Placeholder>
                                        <Placeholder xs={4} size="lg" />
                                </Card.Body>
                                
                            </Card>
                        </Col>
                        <Col sm={6} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%", backgroundColor:'white'}}>
                                <Card.Body style={{backgroundColor:'white'}}>
                                    <Row>
                                        <Col sm={9}>
                                            <Placeholder xs={12} size="lg" />
                                            loading...
                                            <Placeholder as={Card.Subtitle} animation="glow">
                                                <Placeholder xs={6} />
                                            </Placeholder>
                                            <Placeholder xs={4} size="lg" />
                                            
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '15px', marginBottom:'15px'}} className="text-center">
                                        <Col  sm={4}></Col>                                            
                    </Row>
                </Container> ,

        'error':
                <Container>
                    <Row>
                        <Col sm={12} xs={12} className="mt-2" >
                            <Card className="shadow" bg="danger" style={{height: "100%"}}>
                                <Card.Body>
                                    <Row>
                                        <Col className="text-center">
                                            <h2>{errorText}</h2>
                                        </Col>                                            
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                                                   
                </Container>,

        'start':        
                <Container style={{maxWidth:'800px', backgroundColor: polObject.color}}>
                    <Row>
                        <Col sm={12} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%"}}>
                                <Card.Header style={{backgroundColor:'white'}}>
                                {polObject.nocompanyname==1?(
                                    <Row>
                                        <Image width={64} src={polObject.companylogo}/>
                                    </Row>):
                                    (<Row>
                                        <Col sm={2}xs={3}><Image className="border shadow" width={64} height={64} src={polObject.companylogo}/> </Col>
                                        <Col sm={10}xs={9} style={{marginTop:'10px'}}><Card.Title style={{fontWeight:'bolder', fontSize:'2em'}}>{polObject.companyname}</Card.Title></Col>
                                    </Row>)}
                                </Card.Header>
                                <Card.Body style={{backgroundColor:'white'}}>
                                    <Row>
                                        <Col>
                                            <h4>{polObject.branchname}</h4>
                                            {(polObject.showbranchinfo===1||polObject.showbranchinfo===true)?(<><Card.Subtitle>{polObject.branchaddress}</Card.Subtitle><h4>{polObject.managername}</h4></>):(<></>)}
                                        </Col>
                                        <Col className="text-center" sm={2}>
                                            {polObject.showbranchinfo===true&&polObject.showmanagerphoto===true?(<><Image roundedCircle  className="border shadow" width={96} height={96} src={polObject.managerphoto}/></>):(<></>)}                                                    
                                        </Col>
                                    </Row>
                                </Card.Body>
                                <Card.Footer style={{backgroundColor:'#dee2e6'}}>
                                    <Row>
                                        <Col sm={3} xs={3}>{polObject.showphoto===true?(<><Image className="border shadow" width={64} height={64} src={polObject.pollphoto}/></>):(<></>)}</Col>
                                        <Col sm={9} xs={9} className="text-end">
                                            <Card.Subtitle className="mt-2">{polObject.description}</Card.Subtitle>
                                        </Col>                                        
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                    {listOfQuestions.map((value, key) => {
                        const strObj = JSON.parse(value.jsonobject===""?null:value.jsonobject);
                        switch(value.QuestionTypeId){
                            case 1:{        
                                    return (
                                        <Col sm={12} style={{marginTop:'5px'}}>
                                            <Card className="shadow" style={{marginTop:'5px'}}>
                                                <Card.Header style={{fontWeight:'bolder'}}>
                                                    {value.question}
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Control id={key} as="textarea" rows={3} placeholder={t('question.textplaceholder')} onChange={(e)=>{
                                                        listOfQuestions[key].answervalue=e.target.value;
                                                        listOfQuestions[key].metricresult=e.target.value===''?0:listOfQuestions[key].metricvalue;
                                                    }}/>
                                                </Card.Body>
                                            </Card>                                    
                                        </Col>);
                                    break;
                                    }
                            case 2:{    
                                    if (strObj!==null)                                        
                                        return (<Col sm={6} style={{marginTop:'5px'}}>
                                                <Card className="shadow" style={{marginTop:'5px', height:'100%'}}>
                                                    <Card.Header style={{fontWeight:'bolder'}}>
                                                        {value.question}
                                                    </Card.Header>
                                                    <Card.Body>
                                                    <Form key={key} onChange={(e)=>{
                                                            listOfQuestions[key].answervalue=e.target.labels[0].textContent;
                                                            listOfQuestions[key].metricresult=e.target.placeholder===''?0:parseInt(e.target.placeholder);                                                        
                                                        }}>
                                                    {strObj.data.map((val) => {
                                                        return(<Row>
                                                                <Form.Check placeholder={val.metricvalue} className="text-start" name={'r'+key} key={key} id={val.id} type="radio" label={val.value} style={{fontWeight: 'bolder', fontSize:'1.5em', marginLeft:'15px'}}/>
                                                                </Row>)
                                                        }                                            
                                                    )}
                                                    </Form>
                                                    </Card.Body>
                                                </Card>
                                                </Col>);
                                    break;
                                    }                            
                            case 3:{        
                                    if (strObj!==null)                                        
                                    return (<Col sm={6} style={{marginTop:'5px'}}>
                                            <Card className="shadow" style={{marginTop:'5px'}}>
                                            <Card.Header style={{fontWeight:'bolder'}}>
                                                {value.question}
                                            </Card.Header>
                                            <Card.Body>
                                                <Form key={key} onChange={(e)=>{
                                                    const checkboxes = document.getElementsByName(e.target.name);
                                                    var strStart = '';
                                                    var metricStart = 0;
                                                    for(var i=0; i < checkboxes.length; i++) {
                                                        if(checkboxes[i].checked)
                                                            {
                                                                strStart+= checkboxes[i].labels[0].textContent + '; ';
                                                                metricStart+=checkboxes[i].placeholder===''?0:parseInt(checkboxes[i].placeholder);
                                                            }
                                                      }
                                                    listOfQuestions[key].answervalue=strStart;
                                                    listOfQuestions[key].metricresult=metricStart;                                                      
                                                    }}>
                                                {strObj.data.map((val) => {
                                                    return(<>
                                                            <Form.Check placeholder={val.metricvalue} className="text-start" id={val.id} type="checkbox" name={'c'+key} label={val.value} style={{fontWeight: 'bolder', fontSize:'2em'}}/>
                                                            </>)
                                                    }                                            
                                                )}
                                                </Form>
                                                </Card.Body>
                                            </Card>
                                            </Col>);
                                    break;
                                    }                                    
                            case 4:{        
                                    return(
                                        <Col sm={12} style={{marginTop:'5px'}}>
                                        <Card className="shadow" style={{marginTop:'5px'}}>
                                            <Card.Header style={{fontWeight:'bolder'}}>
                                                {value.question}
                                            </Card.Header>
                                            <Card.Body>
                                                <Image className="border shadow" src={`${listOfQuestions.length===0?'':value.pic}`} width={128} height={128}/>
                                                <Form.Control id={key} as="textarea" rows={3} className="mt-4"  placeholder={t('question.textplaceholder')} onChange={(e)=>{
                                                        listOfQuestions[key].answervalue=e.target.value;
                                                        listOfQuestions[key].metricresult=e.target.value===''?0:listOfQuestions[key].metricvalue;
                                                    }}/>
                                            </Card.Body>
                                        </Card>
                                        </Col>
                                    )
                                    break;
                                    }
                            case 5:{
                                    if (strObj!==null)
                                        return(
                                            <Col sm={12} style={{marginTop:'5px'}}>
                                                <Card className="shadow" style={{marginTop:'5px'}}>
                                                    <Card.Header style={{fontWeight:'bolder'}}>
                                                        {value.question}
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Row style={{color:'red', paddingBottom:'5px', fontSize:'0.7em'}}>{t('response.dontrecomend')}</Row>
                                                        {strObj.data!=10?(<Row><Col sm={12}><Form.Range id={key} max={strObj.data} onChange={(e)=>{
                                                        listOfQuestions[key].answervalue=e.target.value;
                                                        listOfQuestions[key].metricresult=listOfQuestions[key].metricvalue===null||listOfQuestions[key].metricvalue===''?0:listOfQuestions[key].metricvalue/e.target.max*e.target.value;
                                                            }}/></Col></Row>):(
                                                        <Row>
                                                            <Col sm={12}>
                                                                <Rating
                                                                    style={{ maxWidth: 800}}
                                                                    itemStyles={customStyles}
                                                                    items={10}
                                                                    value={+listOfQuestions[key].answervalue?parseInt(listOfQuestions[key].answervalue):0}
                                                                    onChange={(r)=>{
                                                                        const newl = [...listOfQuestions];
                                                                        newl[key].answervalue=r;
                                                                        newl[key].metricresult=newl[key].metricvalue===null||newl[key].metricvalue===''?0:newl[key].metricvalue/parseInt(strObj.data)*r;
                                                                        setListOfQuestions(newl)}}
                                                                    highlightOnlySelected
                                                                    spaceBetween="medium"
                                                                    spaceInside="none"
                                                                    transition="zoom"
                                                                    />
                                                            </Col>
                                                            
                                                        </Row>)}
                                                        <Row style={{color:'green', paddingTop:'5px', fontSize:'0.7em'}}><Col className="text-end">{t('response.recomend')}</Col></Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>)
                                    break;
                                    }                            
                            case 6:{
                                    if (strObj!==null)
                                        return(
                                            <Col sm={6} style={{marginTop:'5px'}}>
                                                <Card className="shadow" style={{marginTop:'5px', height:'100%'}}>
                                                    <Card.Header style={{fontWeight:'bolder'}}>
                                                        {value.question}
                                                    </Card.Header>
                                                    <Card.Body>
                                                    <Row style={{alignItems:'center', justifyContent:'center'}}>
                                                            <Rating 
                                                                style={{maxWidth: 300}}
                                                                value={+listOfQuestions[key].answervalue?parseInt(listOfQuestions[key].answervalue):0}
                                                                onChange={(r)=>{
                                                                    const newl = [...listOfQuestions];
                                                                    newl[key].answervalue=r;
                                                                    newl[key].metricresult=newl[key].metricvalue===null||newl[key].metricvalue===''?0:newl[key].metricvalue/parseInt(strObj.data)*r;
                                                                    setListOfQuestions(newl)}}
                                                                spaceBetween="medium"
                                                            />
                                                        
                                                    </Row>
                                                   
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        )
                                    break;
                                }                            
                                };                           
                            }
                        )
                    }           
                    </Row>
                    <Row style={{marginTop: '15px', marginBottom:'60px', paddingBottom: '10px'}} className="text-center">
                        <Col  sm={12}>
                            <Card className="shadow" style={{minHeight: '40px'}}>
                                <Card.Body>
                                    <Button style={{backgroundColor: polObject.color, color:'white', borderColor: polObject.color}} size="lg" variant="warning" className="shadow fw-bolder" onClick={()=>{
                                            savePoll();
                                        }}>
                                        {t('poll.send')}
                                    </Button>                                    
                                </Card.Body>
                                <Card.Footer>
                                    <Card.Title>
                                        {polObject.finalmessage}
                                    </Card.Title>                                                
                                </Card.Footer>                                
                            </Card>
                        </Col>                                                
                    </Row>         
                </Container>,                        
                   
        'finish':           
                <Container>
                    <Row style={{marginTop: '15px', paddingBottom: '10px'}} className="text-center">
                        <Col sm={2}></Col>
                        <Col  sm={8}>
                            <Card style={{minHeight: '40px'}}>
                                <Card.Body>
                                    <Card.Subtitle>
                                        {t('poll.emailrequest')}
                                    </Card.Subtitle>
                                    <Card.Title>
                                        {polObject.companyname}
                                    </Card.Title>
                                        <Form.Group className="mb-3 text-start">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control type="email" value={userEmail} onChange={(e)=>{setUserEmail(e.target.value)}}/>                                                        
                                        </Form.Group>
                                        <Form.Group className="mb-3 text-start">
                                            <Form.Label>{t('poll.name')}</Form.Label>
                                            <Form.Control type="text" value={userName} onChange={(e)=>{setUserName(e.target.value)}}/>                                                        
                                        </Form.Group>
                                        <Card.Subtitle className="mb-3">
                                            {t('poll.phonerequest')}
                                        </Card.Subtitle>
                                        <Form.Group className="mb-3 text-start">
                                            <Form.Label>{t('poll.phone')}</Form.Label>
                                            <Form.Control type="text" value={userPhone} onChange={(e)=>{setUserPhone(e.target.value)}}/>                                                        
                                        </Form.Group>                                        
                                </Card.Body>                                
                                <Card.Footer>
                                    <Button size="lg" variant="warning" className="shadow fw-bolder" onClick={()=>{saveInfo()}}>
                                        {t('poll.end')}
                                    </Button>
                                </Card.Footer>
                            </Card>
                        </Col>                                                            
                    </Row>
                </Container>,                   
        'access':
                <Container>
                    <Row style={{marginTop: '15px', paddingBottom: '10px'}}>
                        <Col sm={3}></Col>
                        <Col sm={6}>
                            <Card bg="warning" className="mt-5 text-center">
                                <Card.Title>{userEmail===''?t('poll.endwithoutemail'):t('poll.endwithemail')}</Card.Title>
                            </Card>
                        </Col>
                        <Col sm={3}></Col>
                    </Row>                                
                </Container>                 
        }[pageState]
    }
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen='md-down'
        size="lg"
    >
        <Modal.Body>
            <h1>{t('poll.saveinprogres')}</h1>
            <Spinner animation="border" />
        </Modal.Body>                    
    </Modal>
</Container>
)}

export default Poll;