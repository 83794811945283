import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Card from 'react-bootstrap/Card';

function MoreAPI() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    return (
        <Container fluid style={{background: 'linear-gradient(#03369c,#ffffff)'}}>
            <Row style={{marginTop:'20px'}}>
                    <Col sm={1} xs={12}></Col>
                    <Col sm={8}  xs={12} className='mt-3 text-start' style={{color:'white'}}>
                        <span style={{marginLeft:'10px',fontSize:'3em', fontWeight:'bolder', color:'#FFB100'}} role='button' onClick={()=>{navigate('/')}}>{t('home.title')}</span><br/>
                        <span style={{marginLeft:'10px'}}>{t('home.text')}</span><br/>                                                
                    </Col>
                    <Col sm={2}  xs={12} className='mt-3 d-flex justify-content-center' style={{minWidth:'200px'}}>
                        <Image src="../logo.png" style={{marginLeft:'38px', width:'128px', height:'128px'}} role='button' onClick={()=>{navigate('/')}}/>                          
                    </Col>
            </Row>
            <Row>
                <Col className='text-center'>
                    <h1 style={{color:'white', marginTop:'15px'}}>{t('moreAPI.core')}</h1>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Row style={{maxWidth:'1200px', marginBottom:'100px'}}>
                    <Card>
                        <Card.Body>                        
                            <Row className='mt-2'>
                                <Col className='text-start'>
                                    <h4>{t('moreAPI.glossary')}</h4>
                                    <span style={{fontWeight:'bold'}}>key: </span>{t('moreAPI.key')}<br/>
                                    <span style={{fontWeight:'bold'}}>branchId: </span>{t('moreAPI.branchid')}<br/>
                                    <span style={{fontWeight:'bold'}}>pollId: </span>{t('moreAPI.pollid')}<br/>
                                    <span style={{fontWeight:'bold'}}>extId: </span>{t('moreAPI.extid')}<br/>
                                    <span style={{fontWeight:'bold'}}>mode: </span>{t('moreAPI.mode')}
                                </Col>
                            </Row>            
                            <Row className='mt-3'>
                                <Form.Label htmlFor="branch-list">{t('moreAPI.branchlist')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="addon1">GET</InputGroup.Text>
                                    <Form.Control
                                        readOnly
                                        id="branch-list"
                                        value='https://api.lllo.online/server/ext/branch/getlist/[key]'
                                    />
                                </InputGroup>
                                <Form.Label htmlFor="poll-list">{t('moreAPI.polllist')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="addon2">GET</InputGroup.Text>
                                    <Form.Control
                                        readOnly
                                        id="poll-list"
                                        value='https://api.lllo.online/server/ext/poll/getlist/[key]'
                                    />
                                </InputGroup>
                                <Form.Label htmlFor="short-link">{t('moreAPI.shortlink')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="addon3">GET</InputGroup.Text>
                                    <Form.Control
                                        readOnly
                                        id="short-link"
                                        value='https://api.lllo.online/server/ext/link/getshort/[key]/[mode]/[pollid]/[branchid]/[extid]'
                                    />
                                </InputGroup>
                                <Form.Label htmlFor="result">{t('moreAPI.getresult')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="addon4">GET</InputGroup.Text>
                                    <Form.Control
                                        readOnly
                                        id="result"
                                        value='https://api.lllo.online/server/ext/result/byextid/[key]/[pollid]/[branchid]/[extid]'
                                    />
                                </InputGroup>
                                <Form.Label htmlFor="result">{t('moreAPI.putresult')}</Form.Label>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text id="addon4">PUT</InputGroup.Text>
                                    <Form.Control
                                        readOnly
                                        id="result"
                                        value='https://api.lllo.online/server/ext/result/byextid/[key]/[pollid]/[branchid]/[extid]'
                                    />
                                </InputGroup>
                            </Row>            
                        </Card.Body>
                    </Card>
                </Row> 
            </Row> 
            <Row  className="fixed-bottom"  style={{height:'65px', backgroundColor:'#03369c'}}>
                <Col className="text-center mt-2">
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyEMail')}</span><br/>
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyPhone')}</span><br/>
                </Col>                
            </Row>
        </Container>        
    );
}

export default MoreAPI;