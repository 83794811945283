import React, { useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import cookies from 'js-cookie';
import Badge from 'react-bootstrap/esm/Badge';

const languages = [
    {
      code: 'ua',
    },
    {
      code: 'en',
    },    
  ]

function MainHeader () {

    const currentLanguageCode = cookies.get('i18next') || 'ua';
    const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
    const { t } = useTranslation();
    
    useEffect(() => {
        document.title = t('app.title')
      }, [currentLanguage, t])

    return (
        <Row>
            <Col xs={8}>
                <a href="/" className="link-body-emphasis link-offset-2 link-underline-opacity-25 link-underline-opacity-75-hover"><Badge bg='warning'>ШО</Badge>.online</a>
            </Col>            
            <Col xs={4} className="d-flex justify-content-end">
                <Button variant="link" size="sm" disabled={currentLanguageCode === 'ua'} onClick={() => {i18next.changeLanguage('ua')}}>ua</Button>
                <Button variant="link" size="sm" disabled={currentLanguageCode === 'en'} onClick={() => {i18next.changeLanguage('en')}}>en</Button>
            </Col>
        </Row>
    )
}

export default MainHeader;