import axios from "axios";
import { useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Placeholder from 'react-bootstrap/Placeholder';
import Image from "react-bootstrap/esm/Image";
import Container from "react-bootstrap/esm/Container";

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'
import Row from "react-bootstrap/esm/Row";

function Go() {
  const { t } = useTranslation();
  const { link } = useParams();
  let navigate = useNavigate();
  const [err, setErr] = useState('');
    
  useEffect(() => {
    axios.get(SERVER_URL+"/ext/link/getlong/"+link, {timeout: 5000}).then((response) => {
        if(!response.data.error){
          navigate(response.data.url);
        }else{
          if(response.data.error===3004)
            navigate('/registerfast/' + response.data.link);
          else
            setErr(response.data.error);
        }            
    }).catch(error => {
      setErr("1007");      
    });                           
  }, []);

  if(err==='')
    return(
      <Container style={{backgroundColor:'#004A97'}}>
          <Row>
              <Col sm={6} className="mt-2" >
                  <Card className="shadow" bg="warning" style={{height: "100%", backgroundColor:'white'}}>
                      <Card.Body className="text-center" style={{backgroundColor:'white'}}>
                          Завантаження...
                      <Placeholder as={Card.Subtitle} animation="glow">
                                  <Placeholder xs={6} />
                              </Placeholder>
                              <Placeholder xs={4} size="lg" />
                      </Card.Body>
                      
                  </Card>
              </Col>
              <Col sm={6} className="mt-2" >
                  <Card className="shadow" bg="warning" style={{height: "100%", backgroundColor:'white'}}>
                      <Card.Body style={{backgroundColor:'white'}}>
                          <Row>
                              <Col sm={9}>
                                  <Placeholder xs={12} size="lg" />
                                  loading...
                                  <Placeholder as={Card.Subtitle} animation="glow">
                                      <Placeholder xs={6} />
                                  </Placeholder>
                                  <Placeholder xs={4} size="lg" />
                                  
                              </Col>
                          </Row>
                      </Card.Body>
                  </Card>
              </Col>
          </Row>
          <Row style={{marginTop: '15px', marginBottom:'15px'}} className="text-center">
                              <Col  sm={4}></Col>                                            
          </Row>
      </Container>
    )
  else
    return (
      <>
        <Row className="align-items-center vh-100">
            <Col className="col-6 mx-auto">
              <Card className="shadow">
                <Card.Header>{t('app.Warning')}</Card.Header>
                <Card.Body>
                  <Card.Title>{t('error.'+err)}</Card.Title>
                </Card.Body>
              </Card>
            </Col>
        </Row>
      </>    
    );
}

export default Go;