import axios from "axios";
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import * as formik from 'formik';
import * as yup from 'yup';

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'

function Register() {
    const { t } = useTranslation();
    let navigate = useNavigate();
    const { Formik } = formik;

    const schema = yup.object().shape({
        companyname: yup.string().required(t('app.CompanyWaiting')),
        email: yup.string().email().required(t('app.EmailWaiting')),
        password: yup.string().min(3, "min 3 char").max(20, "max 20 char").required(t('app.PassWaiting'))
      });
    const initialValues = {
        companyname: "",
        email: "",
        password: ""
      };
    
  const onsubmit = (data) => {
    axios.post(SERVER_URL+"/user/register", data).then((response) => {
      if (response.data.error) {
        alert(response.data.error + ": " + t('error.'+ response.data.error));
      } else {
        navigate("/login");
      }
    });
  };

  const home = () => {
    navigate("/");
  };

  return (
    <Formik
        validationSchema={schema}
        onSubmit={onsubmit}
        initialValues={initialValues}
    >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="row align-items-center vh-100" noValidate onSubmit={handleSubmit}>
                <Col className="col-6 mx-auto">
                    <Card className="shadow">
                        <Card.Header>{t('app.Registration')}</Card.Header>
                        <Card.Body>
                          <Form.Group className="mb-3" controlId="formBasicCompany">
                                <Form.Label>{t('app.CompanyName')}</Form.Label>
                                <Form.Control 
                                    type="text"
                                    name="companyname"
                                    value={values.companyname}
                                    onChange={handleChange}
                                    isInvalid={!!errors.companyname} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.companyname}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{t('app.Pass')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Button variant="primary" type="submit">{t('app.Registration')}</Button>
                            <Button variant="link" onClick={home}>{t('app.Home')}</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Form>
        )}
    </Formik>    
  );
}

export default Register;