import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import Card from 'react-bootstrap/Card';

function MorePoll() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    return (
        <Container fluid style={{background: 'linear-gradient(#03369c,#ffffff)'}}>
            <Row style={{marginTop:'20px'}}>
                    <Col sm={1} xs={12}></Col>
                    <Col sm={8}  xs={12} className='mt-3 text-start' style={{color:'white'}}>
                        <span style={{marginLeft:'10px',fontSize:'3em', fontWeight:'bolder', color:'#FFB100'}} role='button' onClick={()=>{navigate('/')}}>{t('home.title')}</span><br/>
                        <span style={{marginLeft:'10px'}}>{t('home.text')}</span><br/>                                                
                    </Col>
                    <Col sm={2}  xs={12} className='mt-3 d-flex justify-content-center' style={{minWidth:'200px'}}>
                        <Image src="../logo.png" style={{marginLeft:'38px', width:'128px', height:'128px'}} role='button' onClick={()=>{navigate('/')}}/>                          
                    </Col>
            </Row>                         
            <Row>
                <Col className='text-center'>
                    <h1 style={{color:'white', marginTop:'15px'}}>{t('morepoll.core')}</h1>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Row style={{maxWidth:'1200px', marginBottom:'100px'}}>
                    <Col sm={6} xs={12} style={{marginTop:'20px'}}>
                        <Image fluid src='../pollconcept.png'></Image>
                    </Col>                
                    <Col sm={6} xs={12} style={{marginTop:'20px'}}>
                        <Card>
                            <Card.Header>Основні положення</Card.Header>
                            <Card.Body>
                                <Card.Title>"Компанія"</Card.Title>
                                <Card.Text>
                                    Фактичний власник акаунту. При налаштуванні вказується логотип і назва компанії, який відібражається на формі опитування
                                </Card.Text>
                                <Card.Title>"Підрозділ"</Card.Title>
                                <Card.Text>
                                    Підпорядкована одиниця компанії. В залежності від потреб опитування в якості підрозділу може бути відділ компанії, магазин, торгівельна точка, 
                                    співробітник. Головним фактором для створення підрозділу є персоніфіковане отримання результатів опитування. Наприклад для опитування оцінки 
                                    закладу в якості підрозділу виступає окремий заклад, у випадку тестування знань в якості підрозділу може виступати співробітник, а для збору 
                                    необхідної інформації всередені компанії - відділ (структурний підрозділ) підприємства
                                </Card.Text>
                                <Card.Title>"Опитування"</Card.Title>
                                <Card.Text>
                                    Набір питань із варіантами відповідей або полями внесення корисної інформації - текстові повідомлення, значення рейтингу. В залежності від потреби
                                     можна використовувати два види опитувань: односторінкові та покрокові. Односторінкові опитування одразу виводять на екран всі запитання. Доречне 
                                     використнна при невиликих кількостях питань, наприклад оцінка закладу. В режимі покрокового опитування питання виводяться послідовно один за одним. 
                                     Також в налаштуваннях можна вказати кількість питань яке буде задано коритсувачу в випадковому порядку з підготовленого переліку питань. 
                                     Корисна функція при опитуваннях пов'язаних з перевіркою знань, тощо.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </Col>                
                </Row>
            </Row>
            <Row  className="fixed-bottom"  style={{height:'65px', backgroundColor:'#03369c'}}>
                <Col className="text-center mt-2">
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyEMail')}</span><br/>
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyPhone')}</span><br/>
                </Col>                
            </Row>
        </Container>        
    );
}

export default MorePoll;