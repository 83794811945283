import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import uuid from 'react-uuid';
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Card from "react-bootstrap/esm/Card";
import Image from "react-bootstrap/esm/Image";
import Container from "react-bootstrap/esm/Container";
import Button from "react-bootstrap/esm/Button";
import ProgressBar from 'react-bootstrap/ProgressBar'
import Placeholder from 'react-bootstrap/Placeholder';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { EmojiAngry, EmojiSmile} from "react-bootstrap-icons";

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'
import StarRatings from "react-star-ratings";
import Badge from "react-bootstrap/esm/Badge";
import { Scale } from "chart.js";

function Poll() {
    const { t } = useTranslation();
    
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const [listOfQuestions, setListOfQuestions] = useState([{
        id:0,
        question:"",
        num:0,
        QuestionTypeId:0,
        jsonobject:"",
        pic:"",
        metricvalue: 0,
        metricresult:0,
        answerid:'',
        answervalue: ''
    }]);
    const { pollId, branchId, extId } = useParams();
    const [pageState, setPagetState] = useState('load');
    const [qstnNum, setQstnNum] = useState(1);
    const [maxRate, setMaxRate] = useState(10);
    const [listOfRadios, setListOfRadios] = useState([]);
    const [listOfChecks, setListOfChecks] = useState([]);
    const [polObject, setPollObject] = useState({
        branchname:"",
        branchenable:0,
        branchaddress:"",
        managerphoto:"",
        showmanagerphoto: 0,
        managername:"",
        description:"",
        finalmessage:"",
        pollid:"",
        pollphoto:"",
        showbranchinfo: 0,
        showphoto: 0,
        companyname:"",
        companylogo:"",
        enable:0,
        startdate:null,
        endlink: ""
    });
    const [questionObject, setQuestionObject] = useState({
        id:0,
        question:"",
        num:0,
        QuestionTypeId:0,
        jsonobject:"",
        pic:"",
        metricvalue: 0,
        metricresult:0,
        answerid: 0,
        answervalue: ''
        });
    const [polUUID, setPolUUID] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPhone, setUserPhone] = useState('');
    const [userName, setUserName] = useState('');
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        setQstnNum(1);
        axios.get(SERVER_URL+"/poll/startinfo/"+pollId+"/"+branchId+"/"+extId, {mode: 'cors'}).then((response) => {
            if(!response.data.error){
                if(response.data.data.branchenable)
                    {setPollObject(response.data.data);                
                    axios.get(SERVER_URL+"/pollquestion/bypolluuid/"+pollId, {mode: 'cors'}).then((response) => {
                        if (!response.data.error){
                            setListOfQuestions(response.data);
                            setPagetState('start');                            
                        }else{
                            setErrorText(t('error.2003'));
                            setPagetState('error');};
                    });}
                else{
                    setErrorText(t('error.2003'));
                    setPagetState('error');}
            }else{
                setErrorText(t('error.'+response.data.error));
                setPagetState('error');
            };                
        });                           
      }, []);

    const savePoll = () => {
        setShow(true);
        const struuid = uuid();
        setPolUUID(struuid);
        axios.post(
            SERVER_URL+"/pollresponse",
            {
                uuid: struuid,
                PollId: pollId,
                name:'anonimus',
                branchUuid: branchId,
                extId:extId,
                startdate: polObject.startdate
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                axios.post(
                    SERVER_URL+"/responseanswer/list/" + response.data,
                    {listOfQuestions}
                  ).then((response) => {
                    if (response.data.error) {
                        alert(response.data.error);
                      } else {
                        console.log(extId);
                        setShow(false);
                        if(extId===''||extId===undefined){
                            if(polObject.endlink==='')
                                setPagetState('finish');
                            else   
                                window.location.replace(polObject.endlink);
                        }
                        else{
                            setPagetState('access');
                        }
                      }
                  });                
              }
          });
    }   

    const saveInfo = () => {
        axios.put(
            SERVER_URL+"/pollresponse/info/",
            {
                uuid: polUUID,
                email: userEmail,
                phone: userPhone,
                name: userName
            }
          ).then((response) => {
            if (response.data.error) {
                alert(response.data.error);
              } else {
                    setUserEmail('');
                    setUserPhone('');
                    setPagetState('access');
                      }
                  });                
    }
    
    const getQuestionElement = (index) =>{
        if(!index)
            return;
                
        const strObj = JSON.parse(listOfQuestions[index-1].jsonobject===""?null:listOfQuestions[index-1].jsonobject);
        switch(listOfQuestions[index-1].QuestionTypeId){
        case 1:{        
                
                    break;
                }
        case 2:{        
                if (strObj!==null)
                    setListOfRadios(strObj.data);                
                    break;
                }                            
        case 3:{        
                if (strObj!==null)
                    setListOfChecks(strObj.data);                    
                    break;
                }
        case 4:{        
                    break;
                }
        case 5:{
                if (strObj!==null)
                    setMaxRate(strObj.data);
                    break;
                }                            
        case 6:{
            if (strObj!==null)
                setMaxRate(strObj.data);
                break;
            }                            
            };         
        setQstnNum(index);
    }

    return(
        <Container fluid>
    {{
        'load':            
                <Container>
                    <Row>
                        <Col sm={3} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%"}}>
                                <Card.Body className="text-center">
                                    <Image className="border shadow" width={128} height={128} />
                                </Card.Body>
                                <Placeholder as={Card.Subtitle} animation="glow">
                                    <Placeholder xs={12} />
                                </Placeholder>
                            </Card>
                        </Col>
                        <Col sm={9} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%"}}>
                                <Card.Body>
                                    <Row>
                                        <Col sm={9}>
                                            <Placeholder xs={12} size="lg" />
                                            <Placeholder as={Card.Subtitle} animation="glow">
                                                <Placeholder xs={6} />
                                            </Placeholder>
                                            <Placeholder xs={4} size="lg" />
                                        </Col>
                                        <Col className="text-center" sm={2}>
                                            <Image roundedCircle  className="border shadow" width={128} height={128} />
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '10px'}} className="text-center">
                        <Col sm={12}>
                            <Card style={{height: "100%"}}>
                                <Card.Body>
                                    <Row>
                                        <Col  sm={2}>                                    
                                            <Image className="border shadow" width={64} height={64} />
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col sm={9}>
                                            <Placeholder as={Card.Subtitle} animation="glow">
                                                <Placeholder as={Card.Text} xs={6} />
                                            </Placeholder>
                                            <Placeholder as={Card.Subtitle} animation="glow">
                                                <Placeholder as={Card.Text} xs={12} />
                                            </Placeholder>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>                
                    </Row>            
                    <Row style={{marginTop: '15px'}} className="text-center">
                                        <Col  sm={4}></Col>
                                        <Col sm={4}>
                                            <Placeholder.Button variant="warning" xs={6} />
                                        </Col>
                                        <Col sm={4}></Col>
                                            
                    </Row>
                </Container> ,

        'error':
                <Container>
                    <Row>
                        <Col sm={12} xs={12} className="mt-2" >
                            <Card className="shadow" bg="danger" style={{height: "100%"}}>
                                <Card.Body>
                                    <Row>
                                        <Col className="text-center">
                                            <h2>{errorText}</h2>
                                        </Col>                                            
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                                                   
                </Container>,

        'start':        
                <Container>
                    <Row>
                        <Col sm={3} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%"}}>
                                <Card.Body className="text-center">
                                    <Image className="border shadow" width={128} height={128} src={polObject.companylogo}/>
                                </Card.Body>
                                <Card.Subtitle className="text-center">{polObject.companyname}</Card.Subtitle>
                            </Card>
                        </Col>
                        <Col sm={9} className="mt-2" >
                            <Card className="shadow" bg="warning" style={{height: "100%"}}>
                                <Card.Body>
                                    <Row>
                                        <Col sm={9}>
                                            <h1>{polObject.branchname}</h1>
                                            {(polObject.showbranchinfo===1||polObject.showbranchinfo===true)?(<><Card.Subtitle>{polObject.branchaddress}</Card.Subtitle><h2>{polObject.managername}</h2></>):(<></>)}
                                        </Col>
                                        <Col className="text-center" sm={2}>
                                            {polObject.showbranchinfo===true&&polObject.showmanagerphoto===true?(<><Image roundedCircle  className="border shadow" width={128} height={128} src={polObject.managerphoto}/></>):(<></>)}                                                    
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '10px'}} className="text-center">
                        <Col sm={12}>
                            <Card style={{height: "100%"}}>
                                <Card.Body>
                                    <Row>
                                        <Col  sm={2}>
                                            {polObject.showphoto===true?(<><Image className="border shadow" width={64} height={64} src={polObject.pollphoto}/></>):(<></>)}                                                    
                                        </Col>
                                        <Col sm={1}></Col>
                                        <Col sm={9}>
                                            <Card.Title className="mt-2">{polObject.description}</Card.Title>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>                
                    </Row>            
                    <Row style={{marginTop: '15px'}} className="text-center">
                                        <Col  sm={4}></Col>
                                        <Col sm={4}>
                                            <Button size="lg" variant="warning" className="shadow fw-bolder" onClick={()=>{if(listOfQuestions.length===0){extId===''||extId===undefined?setPagetState('access'):setPagetState('finish')}else{setQuestionObject(listOfQuestions[0]);getQuestionElement(1);setPagetState('progress');}}}>
                                                {t('poll.letsstart')}
                                            </Button>
                                        </Col>
                                        <Col sm={4}></Col>
                                            
                    </Row>                            
                </Container>,       
                 
        'progress':     <Container>
                            <Row style={{marginTop: '15px'}} className="text-center">
                                <Col sm={2}></Col>
                                <Col  sm={8}>
                                    <Card bg="warning" className="shadow" style={{minHeight: '40px'}}>
                                        <Card.Body>
                                            <Card.Text>
                                                {polObject.description}
                                            </Card.Text>
                                            <ProgressBar style={{marginTop: '25px'}} now={listOfQuestions.length===0?0:qstnNum/listOfQuestions.length*100} label={qstnNum + ' ' + t('app.From') + ' ' + listOfQuestions.length} />
                                        </Card.Body>                                
                                        <Card.Footer>
                                            <Card.Title>
                                                {listOfQuestions.length===0?'':listOfQuestions[qstnNum-1].question}
                                            </Card.Title>
                                        </Card.Footer>
                                    </Card>
                                </Col>                        
                                <Col sm={2}></Col>
                            </Row>
                            <Row style={{marginTop: '15px'}} className="text-center">
                                <Col sm={2}></Col>
                                <Col  sm={8}>
                                    <Card style={{minHeight: '40px', background: "#d3d4d5"}} className="shadow">
                                        <Card.Body>
                                        {
                                            {
                                            1: <><Form.Control as="textarea" rows={4} placeholder={t('question.textplaceholder')} value={questionObject.answervalue} onChange={(e)=>{
                                                setQuestionObject({...questionObject, answervalue: e.target.value, metricresult:e.target.value===''?0:questionObject.metricvalue}); }}/></>,
                                            2: <>
                                                    <Form>
                                                    {listOfRadios.map((value, key) => {
                                                        return(<> 
                                                                <Form.Control hidden value={value.metricvalue} onChange={(e)=>{}}/>
                                                                <Form.Check placeholder={value.metricvalue} className="text-start" key={key} id={value.id} type="radio" name="radiogroup1" label={value.value} style={{fontWeight: 'bolder', fontSize:'1.5em', marginBottom:'20px'}}
                                                                    checked={value.id===questionObject.answerid&&questionObject.QuestionTypeId===2?true:false} onChange={(e)=>{
                                                                    setQuestionObject({...questionObject, answerid: e.target.id, answervalue: e.target.labels[0].textContent, metricresult:e.target.placeholder===''?0:parseInt(e.target.placeholder)})
                                                                }}/>
                                                                </>
                                                            ) 
                                                        }
                                                    )}
                                                    </Form>
                                                </>,
                                            3: <>
                                                    <Form>
                                                    {listOfChecks.map((value, key) => {
                                                        return(
                                                                <Form.Check placeholder={value.metricvalue} className="text-start" key={key} id={value.id} type="checkbox" name="checkgroup1" label={value.value} style={{fontWeight: 'bolder', fontSize:'1.5em', marginBottom:'20px'}}
                                                                checked={questionObject.answerid.includes(value.id)?true:false} onChange={(e)=>{
                                                                    var strStart = questionObject.answerid;
                                                                    var strValueStart = questionObject.answervalue;
                                                                    if(e.target.checked)
                                                                        {
                                                                            strStart = strStart + e.target.id + ';';
                                                                            strValueStart = strValueStart + e.target.labels[0].textContent + '; ';
                                                                        }
                                                                    else
                                                                        {
                                                                            strStart = strStart.toString().replace(e.target.id + ';', '');
                                                                            strValueStart = strValueStart.toString().replace(e.target.labels[0].textContent + '; ', '');
                                                                        }

                                                                    setQuestionObject({...questionObject, answerid: strStart, answervalue: strValueStart, metricresult:e.target.placeholder===''?questionObject.metricresult:e.target.checked?questionObject.metricresult+parseInt(e.target.placeholder):questionObject.metricresult-parseInt(e.target.placeholder)})}}/>
                                                            ) 
                                                        }
                                                    )}
                                                    </Form>
                                                </>,
                                            4: <>
                                                    <Image className="border shadow" src={`${listOfQuestions.length===0?'':listOfQuestions[qstnNum-1].pic}`} width={128} height={128}/>
                                                    <Form.Control as="textarea" rows={4} className="mt-4"  placeholder={t('question.textplaceholder')} value={questionObject.answervalue} onChange={(e)=>{
                                                        setQuestionObject({...questionObject, answervalue: e.target.value, metricresult:e.target.value===''?0:questionObject.metricvalue});}}/>
                                                </>,
                                            5: <>
                                                    <Row><Col sm={2} xs={2} className="text-start"><EmojiAngry color="red" size={28}/></Col><Col xs={8} sm={8}></Col><Col sm={2} xs={2} className="text-end"><EmojiSmile color="green" size={28}/></Col></Row>
                                                    <Row><Col  sm={12}><Form.Range max={maxRate} value={questionObject.answervalue} onChange={(e)=>{
                                                        setQuestionObject({...questionObject, answervalue: e.target.value, metricresult: questionObject.metricvalue===null||questionObject.metricvalue===''?0:questionObject.metricvalue/maxRate*e.target.value}); 
                                                        }}/></Col></Row>
                                                    <Row><Col sm={2} xs={2} className="text-start">0</Col><Col xs={8} sm={8} className="text-center">{maxRate/2}</Col><Col sm={2} xs={2} className="text-end">{maxRate}</Col></Row>
                                                </>,
                                            6: <>
                                                <Row><Col  sm={12}>
                                                    <StarRatings
                                                        starRatedColor="orange"
                                                        starEmptyColor="white"
                                                        starHoverColor="orange"
                                                        numberOfStars={parseInt(maxRate)}
                                                        starDimension='50px'
                                                        rating={+questionObject.answervalue?parseInt(questionObject.answervalue):0}
                                                        changeRating={(rating)=>{
                                                            setQuestionObject({...questionObject, answervalue: rating, metricresult: questionObject.metricvalue===null||questionObject.metricvalue===''?0:questionObject.metricvalue/maxRate*rating});}}
                                                    /></Col></Row>                                                
                                            </>
                                            }[listOfQuestions.length===0?0:listOfQuestions[qstnNum-1].QuestionTypeId]
                                        }
                                        </Card.Body>                                
                                    </Card>
                                </Col>                        
                                <Col sm={2}></Col>
                            </Row>
                            <Row style={{marginTop: '15px', marginBottom:'50px'}} className="text-center">
                                <Col  sm={2} xs={1}></Col>
                                <Col sm={8}  xs={10} className="text-center">
                                    <Button size="lg" variant="light" className="fw-bolder" onClick={()=>{if(qstnNum>1){
                                        listOfQuestions.find(obj => {
                                            if(obj.id === questionObject.id){
                                                obj.answerid = questionObject.answerid;
                                                obj.answervalue = questionObject.answervalue;
                                            }
                                        });
                                        setQuestionObject(listOfQuestions[qstnNum-2]);
                                        getQuestionElement(qstnNum-1);
                                        }}}>
                                        {t('app.Back')}
                                    </Button>{'  '}
                                    <Button size="lg" variant="warning" className="shadow fw-bolder" onClick={()=>{if(qstnNum===listOfQuestions.length){
                                        listOfQuestions.find(obj => {
                                            if(obj.id === questionObject.id){
                                                obj.answerid = questionObject.answerid;
                                                obj.answervalue = questionObject.answervalue;
                                                obj.metricresult = questionObject.metricresult;
                                            }
                                        });
                                        savePoll()
                                        }else{
                                        listOfQuestions.find(obj => {
                                            if(obj.id === questionObject.id){
                                                obj.answerid = questionObject.answerid;
                                                obj.answervalue = questionObject.answervalue;
                                                obj.metricresult = questionObject.metricresult;
                                            }
                                        });
                                        setQuestionObject(listOfQuestions[qstnNum]);
                                        getQuestionElement(qstnNum+1);}}}>
                                        {t('app.Next')}
                                    </Button>
                                </Col>
                                <Col sm={2} xs={1}></Col>                                        
                            </Row>
                        </Container>,
                   
        'finish':           <Container>
                                <Row style={{marginTop: '15px'}} className="text-center">
                                    <Col sm={2}></Col>
                                    <Col  sm={8}>
                                        <Card bg="warning" className="shadow" style={{minHeight: '40px'}}>
                                            <Card.Body>
                                                <Card.Title>
                                                    {polObject.finalmessage}
                                                </Card.Title>                                                
                                            </Card.Body>                                
                                        </Card>
                                    </Col>                        
                                    <Col sm={2}></Col>
                                </Row>
                                <Row style={{marginTop: '15px'}} className="text-center">
                                    <Col sm={2}></Col>
                                    <Col  sm={8}>
                                        <Card style={{minHeight: '40px'}}>
                                            <Card.Body>
                                                <Card.Subtitle>
                                                    {t('poll.emailrequest')}
                                                </Card.Subtitle>
                                                <Card.Title>
                                                    {polObject.companyname}
                                                </Card.Title>
                                                    <Form.Group className="mb-3 text-start">
                                                        <Form.Label>Email</Form.Label>
                                                        <Form.Control type="email" value={userEmail} onChange={(e)=>{setUserEmail(e.target.value)}}/>                                                        
                                                    </Form.Group>
                                                    <Form.Group className="mb-3 text-start">
                                                        <Form.Label>{t('poll.name')}</Form.Label>
                                                        <Form.Control type="text" value={userName} onChange={(e)=>{setUserName(e.target.value)}}/>                                                        
                                                    </Form.Group>
                                                    <Card.Subtitle className="mb-3">
                                                        {t('poll.phonerequest')}
                                                    </Card.Subtitle>
                                                    <Form.Group className="mb-3 text-start">
                                                        <Form.Label>{t('poll.phone')}</Form.Label>
                                                        <Form.Control type="text" value={userPhone} onChange={(e)=>{setUserPhone(e.target.value)}}/>                                                        
                                                    </Form.Group>                                        
                                            </Card.Body>                                
                                            <Card.Footer>
                                                <Button size="lg" variant="warning" className="shadow fw-bolder" onClick={()=>{saveInfo()}}>
                                                    {t('poll.end')}
                                                </Button>
                                            </Card.Footer>
                                        </Card>
                                    </Col>                                                            
                                </Row>
                            </Container>,                   
        'access':
                    <Container>
                        <Row>
                            <Col sm={3}></Col>
                            <Col sm={6}>
                                <Card bg="warning" className="mt-5 text-center">
                                    <Card.Title>{userEmail===''?t('poll.endwithoutemail'):t('poll.endwithemail')}</Card.Title>
                                </Card>
                            </Col>
                            <Col sm={3}></Col>
                        </Row>                                
                    </Container>                 
        }[pageState]
    }
    <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        fullscreen='md-down'
        size="lg"
    >
        <Modal.Body>
            <h1>{t('poll.saveinprogres')}</h1>
            <Spinner animation="border" />
        </Modal.Body>                    
    </Modal>
</Container>
)}

export default Poll;