import React, { useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../helpers/AuthContext";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import * as formik from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { SERVER_URL } from '../helpers/constants'
import Row from "react-bootstrap/esm/Row";

function Login() {
    const { t } = useTranslation();
    const { setAuthState } = useContext(AuthContext);

    let navigate = useNavigate();
    const { Formik } = formik;

    const schema = yup.object().shape({
        email: yup.string().email().required(t('app.EmailWaiting')),
        password: yup.string().min(3, "min 3 char").max(20, "max 20 char").required(t('app.PassWaiting'))
      });
    const initialValues = {
        email: "",
        password: ""
      };
    
  const onsubmit = (data) => {
    axios.post(SERVER_URL+"/user/login", data).then((response) => {
      if (response.data.error) {
        alert(response.data.error + ": " + t('error.'+ response.data.error));
        //alert('Помилка авторизації');
      } else {
        localStorage.setItem("accessToken", response.data.token);
        setAuthState({
          email: response.data.email,
          id: response.data.id,
          companyid: response.data.companyid,
          status: true,
        });
        navigate("/main");
      }
    });
  };

  const register = () => {
    navigate("/register");
  };

  const recover = (data) => {
    axios.post(SERVER_URL+"/user/recover", data).then((response) => {
      if (response.data.error) {
        alert(response.data.error + ": " + t('error.'+ response.data.error));
      } else {
        alert('sended to your mail')
      }
    });
  };

  return (
    <Formik
        validationSchema={schema}
        onSubmit={onsubmit}
        initialValues={initialValues}
    >
        {({ handleSubmit, handleChange, values, touched, errors }) => (
            <Form className="row align-items-center vh-100" noValidate onSubmit={handleSubmit}>
                <Col className="col-6 mx-auto">
                    <Card className="shadow">
                        <Card.Header>{t('app.Autorization')}</Card.Header>
                        <Card.Body>
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control 
                                    type="email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    isInvalid={!!errors.email} />        
                                <Form.Control.Feedback type="invalid">
                                    {errors.email}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>{t('app.Pass')}</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    value={values.password}
                                    onChange={handleChange}
                                    isInvalid={!!errors.password}/>
                                <Form.Control.Feedback type="invalid">
                                    {errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Row>
                              <Col sm={6}>
                                <Button variant="primary" type="submit">{t('app.Login')}</Button>
                                <Button variant="link" onClick={register}>{t('app.Registration')}</Button>
                              </Col>
                              <Col sm={6} className="text-end">
                                <Button style={{color:'red'}} variant="link" onClick={()=>{recover({email:values.email})}}>{t('app.Recover')}</Button>
                              </Col>
                            </Row>                            
                        </Card.Body>
                    </Card>
                </Col>
            </Form>
        )}
    </Formik>    
  );
}

export default Login;