import React, { useEffect  } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';
import { QRCode } from 'react-qrcode-logo';

import { CLIENT_URL } from '../helpers/constants'
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";

function Print() {
  const { mode, id1, id2 } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  
  useEffect(() => {
      if (!localStorage.getItem("accessToken")) {
          navigate("/login");
      }
    }, []);

  return (
    <Container fluid style={{width:'100%', height:'100%', justifyContent:'center', alignItems:'center'}}>
        <Row>
            <Col sm={4} xs={2}></Col>
            <Col sm={4} xs={8}>
              <Card className="shadow">
                  <Card.Header className="shadow text-center">
                    {t('poll.link')}
                  </Card.Header>
                  <Card.Body className="text-center">
                    <QRCode value={CLIENT_URL+'/'+mode+'/'+id1+'/'+id2}/>
                  </Card.Body>
              </Card>
            </Col>
            <Col sm={4} xs={2}></Col>
        </Row>        
    </Container>    
  );
}

export default Print;