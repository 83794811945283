import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Menu from "../components/Menu";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ListGroup from "react-bootstrap/esm/ListGroup";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from "react-bootstrap/esm/Image";
import uuid from 'react-uuid';
import { CaretRightFill, SkipForwardFill, SkipBackwardFill, CaretLeftFill, HouseAdd } from "react-bootstrap-icons";

import { useTranslation } from 'react-i18next';
import { SERVER_URL } from '../helpers/constants'

function Branches() {
    const { t } = useTranslation();
    const inputFile = useRef(null);
    const [listOfBranches, setListOfBranches] = useState([]);
    const [postImage, setPostImage] = useState();
    const [companyId, setCompanyId] = useState(0);
    const [branchcount, setBranchcount] = useState(0);
    const [branchsearch, setBranchsearch] = useState('');
    const [branchpagenum, setBranchpagenum] = useState(1);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
    
    const [branchObject, setbranchObject] = useState({
        id:0,
        uuid:"",
        CompanyId:0,
        name:"",
        address:"",
        managerphoto:"",
        showmanagerphoto: false,
        managername:"",
        enable: 0,
        default: 0,
        enabledexpiration: null
    });

    const [branchObjectLoad, setbranchObjectLoad] = useState({
        id:0,
        uuid:"",
        CompanyId:0,
        name:"",
        address:"",
        managerphoto:"",
        showmanagerphoto: false,
        managername:"",
        enable: 0,
        default: 0,
        enabledexpiration: null
    });
    
    const saveBranch = async () => {
        if(branchObjectLoad.id===0){
            await axios.get(SERVER_URL+"/user/auth", {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((response) => {
                if (response.data.error) {
                  alert(response.data.error);
                } else {
                    axios.post(
                        SERVER_URL+"/branch",
                        {
                            uuid: uuid(),
                            name: branchObject.name,
                            address: branchObject.address,
                            managername: branchObject.managername,
                            managerphoto: branchObject.managerphoto,
                            showmanagerphoto: branchObject.showmanagerphoto,
                            CompanyId: response.data.companyid
                        },
                        {
                          headers: { accessToken: localStorage.getItem("accessToken") },
                        }
                      ).then((response) => {
                        if (response.data.error) {
                            alert(response.data.error);
                          } else {
                            setbranchObjectLoad(response.data);
                            setbranchObject(response.data);
                            setListOfBranches([...listOfBranches, response.data]);
                          }
                      });
                }
              });            
        }else{
            await axios.put(
                SERVER_URL+"/branch",
                {
                    name: branchObject.name,
                    address: branchObject.address,
                    managername: branchObject.managername,
                    managerphoto: branchObject.managerphoto,
                    showmanagerphoto: branchObject.showmanagerphoto,
                    id: branchObject.id
                },
                {
                  headers: { accessToken: localStorage.getItem("accessToken") },
                }
              ).then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                  } else {
                    setbranchObjectLoad(branchObject);
                    listOfBranches.find(obj => {
                        if(obj.id === branchObject.id){
                            obj.name = branchObject.name;
                        }
                        return null;
                    })
                  }
              }
    
              );
        }        
    };

    const getBranchList = async (num, searchvar, companyIdvar) => {
        const extrafiltr = searchvar.length>0?searchvar:'all';
        try {
            axios.get(SERVER_URL+"/branch/partly/"+companyIdvar + "/" + num + "/" + extrafiltr + "/").then((response) => {
                setListOfBranches(response.data);
                if (response.data.length > 0){
                    setbranchObject(response.data[0]);
                    setbranchObjectLoad(response.data[0]);
                    setPostImage(response.data[0].managerphoto);
                }                
            });
            axios.get(SERVER_URL+"/branch/count/"+companyIdvar).then((response) => {
                if (!response.error)
                    setBranchcount(response.data);
            });
        } catch (error) {
            alert(error)
        }
    }

    useEffect(() => {
        axios.get(SERVER_URL+"/user/auth", {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.error) {
              alert(response.data.error);
            } else {
                setCompanyId(response.data.companyid);
                axios.get(SERVER_URL+"/branch/count/"+response.data.companyid).then((resp) => {
                    if (!resp.error)
                        setBranchcount(resp.data);
                });
                axios.get(SERVER_URL+"/branch/partly/"+response.data.companyid + "/1/all").then((response) => {
                    setListOfBranches(response.data);
                    if (response.data.length > 0){
                        setbranchObject(response.data[0]);
                        setbranchObjectLoad(response.data[0]);
                        setPostImage(response.data[0].managerphoto);                                                                        
                    }

                });            
            }
          });        
      }, []);

    const getBranch = async (uuid) =>{
        axios.get(SERVER_URL+"/branch/byuuid/"+uuid).then((response) => {
            if (!response.error){
                setbranchObject(response.data);
                setbranchObjectLoad(response.data);
                setPostImage(response.data.managerphoto);
            }

        });
    };

    const addBranch = () => {
        setbranchObject({id:0,
            uuid:"",
            companyid:0,
            name:"",
            address:"",
            managerphoto:"",
            showmanagerphoto: false,
            managername:"",
            enable: 0,
            default: 0,
            enabledexpiration: null});
        setbranchObjectLoad({id:0,
            uuid:"",
            companyid:0,
            name:"",
            address:"",
            managerphoto:"",
            showmanagerphoto: false,
            managername:"",
            enable: 0,
            default: 0,
            enabledexpiration: null});
        setPostImage('');
    }

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        if (file){
            const base64 = await convertToBase64(file);        
            setPostImage(base64);
            setbranchObject({ ...branchObject, managerphoto: base64});            
        }        
      };
    
    return (
        <>
            <Menu ActiveKey="/branches"/>
            <Container fluid>
                <Row style={{marginTop:'15px'}}>
                    <Col sm={3} className="text-center"  style={{minWidth:'280px'}}>
                        <Row style={{margin:'3px'}} className="justify-content-center">
                            <Button size="lg" variant="primary" className="shadow" onClick={() => {addBranch()}}>
                                {t('branch.add')}{'  '}<HouseAdd/>
                            </Button>
                        </Row>
                        <Row style={{margin:'3px'}} className="mt-2">
                            <Form.Control placeholder={t('app.Search')} value={branchsearch} onChange={(e)=>{setBranchsearch(e.target.value);getBranchList(branchpagenum, e.target.value, companyId)}}/>                            
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                            <ListGroup as="ol" role="button" style={{maxHeight: '800px'}} className="overflow-y-auto">
                                {listOfBranches.map((value, key) => {
                                    return(
                                            <ListGroup.Item key={key} action as="li" id={value.uuid} className="shadow" variant={value.uuid===branchObjectLoad.uuid? "primary": "outline-primary"}
                                                onClick={() =>{getBranch(value.uuid)}}>
                                                    <Row>
                                                        <Col xs={9} sm={9} className="text-start">
                                                            <Row>
                                                                <div className="col-11 text-truncate fw-bolder">
                                                                    {value.name}
                                                                </div>
                                                            </Row>
                                                            {!value.enable ? (
                                                                <Row><small>{t('app.Expired')}</small></Row>
                                                            ) : (!value.default ? (<Row><small>{t('app.PayPlanExpiration')} {value.enabledexpiration} </small></Row>):(<Row><small>{t('app.PayPlanFree')}</small></Row>)                                                    
                                                            )}                                                
                                                        </Col>
                                                        <Col xs={3} sm={3} className="text-end">
                                                            {!value.enable ? (
                                                                <Badge bg="danger" pill>
                                                                    {t('app.Block')}
                                                                </Badge>
                                                            ) : (
                                                                <Badge bg="success" pill>
                                                                    {t('app.Active')}
                                                                </Badge>
                                                            )}                                                                                                                                            
                                                        </Col>
                                                    </Row>
                                            </ListGroup.Item>
                                    ) 
                                    }
                                )}                            
                            </ListGroup>
                        </Row>
                        <Row className="mt-4">
                            <ButtonToolbar className="justify-content-center"  style={{marginBottom:'10px'}}>
                                <ButtonGroup className="text-center shadow" size="sm">
                                    <Button variant="outline-primary" onClick={()=>{if(branchpagenum>1){setBranchpagenum(1); getBranchList(1, branchsearch, companyId)}}}><SkipBackwardFill/></Button>
                                    <Button variant="outline-primary" onClick={()=>{if(branchpagenum>1){setBranchpagenum(branchpagenum-1); getBranchList(branchpagenum-1, branchsearch, companyId)}}}><CaretLeftFill/></Button>
                                    <Button variant="primary" disabled>{branchpagenum} {'  '} {t('app.From')} {'  '} {Math.ceil(branchcount/10)}</Button>
                                    <Button variant="outline-primary" onClick={()=>{if(branchpagenum*10<branchcount){setBranchpagenum(branchpagenum+1); getBranchList(branchpagenum+1, branchsearch, companyId)}}}><CaretRightFill/></Button>
                                    <Button variant="outline-primary" onClick={()=>{if(branchpagenum<Math.ceil(branchcount/10)){setBranchpagenum(Math.ceil(branchcount/10)); getBranchList(Math.ceil(branchcount/10), branchsearch, companyId)}}}><SkipForwardFill/></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Row>
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <Col sm={8}><h1>{t('branch.branch')} ({branchObjectLoad.id===0 ? t('app.create') : t('app.edit')}*)</h1></Col>
                            <Col sm={4} className="text-end" style={{marginTop:'2px'}}>
                                <Button variant={branchObject.name===branchObjectLoad.name&&branchObject.address===branchObjectLoad.address&&branchObject.managername===branchObjectLoad.managername&&branchObject.managerphoto===branchObjectLoad.managerphoto&&branchObject.showmanagerphoto===branchObjectLoad.showmanagerphoto? "outline-primary": "primary"} size="lg"
                                    onClick={() => {saveBranch()}}>
                                {t('app.Save')}
                                </Button>{' '}                                
                            </Col>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('app.name')}</Form.Label>
                                    <Col sm="5">
                                        <Form.Control style={{fontWeight: "bolder"}} value={branchObject.name} onChange={(e) => { setbranchObject({ ...branchObject, name: e.target.value})}}/>
                                    </Col>
                                    <Col sm="5">{branchObject.enable ? '':(
                                        <Badge size="lg" bg="info" style={{marginTop:'5px'}}>
                                            <span className="fs-5">{t('app.PayForEnable')}</span>
                                        </Badge>                                        
                                        )}                                        
                                    </Col>
                            </Form.Group>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('app.address')}</Form.Label>
                                    <Col sm="10">
                                    <Form.Control value={branchObject.address} onChange={(e) => { setbranchObject({ ...branchObject, address: e.target.value})}}/>
                                    </Col>
                            </Form.Group>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('branch.managername')}</Form.Label>
                                    <Col sm="10">
                                        <Form.Control value={branchObject.managername} onChange={(e) => { setbranchObject({ ...branchObject, managername: e.target.value})}}/>
                                    </Col>                                    
                            </Form.Group>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('branch.showmanagerphoto')}</Form.Label>
                                <Col sm="3">
                                    <Form.Check style={{marginTop: '10px'}}                                           
                                        checked={branchObject.showmanagerphoto}
                                        type="switch"
                                        onChange={(e) => { setbranchObject({ ...branchObject, showmanagerphoto: e.target.checked})}}
                                    />                                        
                                </Col>
                            </Form.Group>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('branch.managerphoto')}</Form.Label>
                                    <Col sm="9">
                                    <input id="myInput"
                                        type="file"
                                        accept=".jpeg, .png, .jpg"
                                        ref={inputFile}
                                        style={{display: 'none'}}
                                        onChange={(e) => handleFileUpload(e)}/>
                                    <Image roundedCircle className="border shadow mt-4" role="button" alt={t('app.ClickToChoose')} src={`${postImage}`} width={128} height={128} onClick={()=>{inputFile.current.click()}}/>
                                    </Col>                                    
                            </Form.Group>                            
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Branches;