import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

function Home() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    const renderTooltip = (props) => (
        <Tooltip id="more-tooltip" {...props}>
        {t('home.moretooltip')}
        </Tooltip>
    );

    return (
        <Container fluid style={{background: 'linear-gradient(#03369c,#ffffff)'}}>
            <Row style={{marginTop:'20px'}}>
                    <Col sm={1} xs={12}></Col>
                    <Col sm={8}  xs={12} className='mt-3 text-start' style={{color:'white'}}>
                        <span style={{marginLeft:'10px',fontSize:'3em', fontWeight:'bolder', color:'#FFB100'}}>{t('home.title')}</span><br/>
                        <span style={{marginLeft:'10px'}}>{t('home.text')}</span><br/>                        
                        <Button size='lg' style={{backgroundColor:'#03369c', margin:'10px'}} onClick={() => {navigate("/main")}}>{t('home.buttontext')}</Button>
                    </Col>
                    <Col sm={2}  xs={12} className='mt-3 d-flex justify-content-center' style={{minWidth:'200px'}}>
                        <Image src="../logo.png" style={{marginLeft:'38px', width:'128px', height:'128px'}}/>                          
                    </Col>
            </Row>             
            <Row style={{marginTop:'20px'}} className='d-flex justify-content-center'>
                <Col style={{maxWidth:'1200px'}}>
                <CardGroup>
                    <Card>
                        <Card.Img variant="top" src="../poll1.png" className='shadow'/>
                        <Card.Body>
                            <Card.Title>{t('home.poll')}</Card.Title>
                            <Card.Text>
                                {t('home.polltext')}<br/>
                                <span className='fw-bolder'>{t('home.polltesttext1')}</span>
                            </Card.Text>
                            <a href={t('home.polllink')}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-lg" role="button"
                                    style={{backgroundColor:'#03369c', color:'white'}}>{t('home.polltest')}</a>                            
                            <Card.Text>
                                <span className='fw-bolder'>{t('home.polltesttext2')}</span>
                            </Card.Text>
                            <a href={t('home.polloplink')}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-lg" role="button"
                                    style={{backgroundColor:'#03369c', color:'white'}}>{t('home.polloptest')}</a>
                        </Card.Body>
                        <Card.Footer className='shadow'>
                            <Button variant='link' size='sm' className="text-muted text-decoration-none" onClick={()=>{navigate('/morepoll')}}>{t('home.more')}</Button>                             
                        </Card.Footer>
                    </Card>
                    <Card className='shadow' style={{marginLeft:'5px', marginRight:'5px'}}>
                        <Card.Img variant="top" src="../api1.png" className='shadow'/>
                        <Card.Body>
                            <Card.Title>{t('home.api')}</Card.Title>
                            <Card.Text>
                                {t('home.apitext')}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant='link' size='sm' className="text-muted text-decoration-none" onClick={()=>{navigate('/moreapi')}}>{t('home.more')}</Button>                             
                        </Card.Footer>
                    </Card>
                    <Card className='shadow'>
                        <Card.Img variant="top" src="../analitics1.png" className='shadow'/>
                        <Card.Body>
                            <Card.Title>{t('home.metric')}</Card.Title>
                            <Card.Text>
                                {t('home.metrictext')}
                            </Card.Text>
                        </Card.Body>
                        <Card.Footer>
                            <Button variant='link' size='sm' className="text-muted text-decoration-none" onClick={()=>{navigate('/moremetric')}}>{t('home.more')}</Button>                             
                        </Card.Footer>
                    </Card>
                    </CardGroup>
                </Col>                
            </Row>
            <Row style={{marginBottom:'100px'}}></Row>
            <Row  className="fixed-bottom"  style={{height:'65px', backgroundColor:'#03369c'}}>
                <Col className="text-center mt-2">
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyEMail')}</span><br/>
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyPhone')}</span><br/>
                </Col>                
            </Row>            
        </Container>        
    );
}

export default Home;