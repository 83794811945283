import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import Menu from "../components/Menu";
import QuestionConstructor from "../components/QuestionConstructor";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import ListGroup from "react-bootstrap/esm/ListGroup";
import Badge from 'react-bootstrap/Badge';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Image from "react-bootstrap/esm/Image";
import uuid from 'react-uuid';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Table from 'react-bootstrap/Table';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import { PlusCircle, XCircleFill, FileEarmarkPlusFill, PencilSquare, CaretRightFill, SkipForwardFill, SkipBackwardFill, CaretLeftFill, ArrowClockwise } from "react-bootstrap-icons";

import { useTranslation } from 'react-i18next';
import { SERVER_URL, CLIENT_URL } from '../helpers/constants'

function Polls() {
    const [key, setKey] = useState('info');
    const { t } = useTranslation();
    const inputFile = useRef(null);
    const [listOfMetrics, setListOfMetrics] = useState([]);
    const [listOfPolls, setListOfPolls] = useState([]);
    const [listOfQuestions, setListOfQuestions] = useState([]);
    const [listOfLinks, setListOfLinks] = useState([]);
    const [listOfResponses, setListOfResponses] = useState([]);
    const responseStatus = [t('response.statuswait'), t('response.statusnew'), t('response.statusdone'), t('response.statusspam')];
    const responseStatusColor = ["warning", "secondary", "success", "danger"];
    const [postImage, setPostImage] = useState('');
    const [questionImage, setQuestionImage] = useState('');
    
    const questionTypes = [t('poll.texttype'), t('poll.radiotype'), t('poll.checktype'), t('poll.imagetype'), t('poll.ranktype'), t('poll.ratetype')];
    const [questionStatus, setQuestionStatus] = useState('Add');

    const [show, setShow] = useState(false);
    const [questioncount, setQuestioncount] = useState(0);
    const [responsecount, setResponsecount] = useState(0);
    const [responsesearch, setResponsesearch] = useState('');
    const [responsestatusfilter, setResponsestatusfilter] = useState(-1);
    const [responsepagenum, setResponsepagenum] = useState(1);

    const [companyId, setCompanyId] = useState(0);
    const [pollscount, setPollscount] = useState(0);
    const [pollssearch, setPollssearch] = useState('');
    const [pollspagenum, setPollspagenum] = useState(1);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader();
          fileReader.readAsDataURL(file);
          fileReader.onload = () => {
            resolve(fileReader.result);
          };
          fileReader.onerror = (error) => {
            reject(error);
          };
        });
      };
    
    const [QuestionsObject, setQuestionsObject] = useState({
        id:0,
        question:"",
        num:0,
        QuestionTypeId:0,
        jsonobject:"",
        pic:"",
        metricvalue: 0
    });

    const [PollObject, setPollObject] = useState({
        id:0,
        uuid:"",
        CompanyId:0,
        MetricId:0,
        name:"",
        randomcount:0,
        description:"",
        finalmessage:"",
        pollphoto:"",
        resultemail:"",
        enable: 0,
        showbranchinfo: 0,
        showphoto: 0,
        default: 0,
        usemetric: 0,
        useendlink: 0,
        endlink: "",
        nocompanyname: 0,
        color: "#004A97"
    });

    const [PollObjectLoad, setPollObjectLoad] = useState({
        id:0,
        uuid:"",
        CompanyId:0,
        MetricId:0,
        name:"",
        randomcount:0,
        description:"",
        finalmessage:"",
        pollphoto:"",
        resultemail:"",
        enable: 0,
        showbranchinfo: 0,
        showphoto: 0,
        default: 0,
        usemetric: 0,
        useendlink: 0,
        endlink: "",
        nocompanyname: 0,
        color: "#004A97"
    });
    
    const savePoll = async () => {
        if(PollObjectLoad.id===0){
            await axios.get(SERVER_URL+"/user/auth", {
                headers: {
                  accessToken: localStorage.getItem("accessToken"),
                },
              })
              .then((response) => {
                if (response.data.error) {
                  alert(response.data.error);
                } else {
                    axios.post(
                        SERVER_URL+"/poll",
                        {
                            uuid: uuid(),
                            name: PollObject.name,
                            randomcount: PollObject.randomcount,
                            description: PollObject.description,
                            finalmessage: PollObject.finalmessage,
                            pollphoto: PollObject.pollphoto,
                            resultemail: PollObject.resultemail,
                            showbranchinfo: PollObject.showbranchinfo,
                            showphoto: PollObject.showphoto,
                            useendlink: PollObject.useendlink,
                            endlink: PollObject.endlink,
                            nocompanyname: PollObject.nocompanyname,
                            color: PollObject.color,
                            CompanyId: response.data.companyid
                        },
                        {
                          headers: { accessToken: localStorage.getItem("accessToken") },
                        }
                      ).then((response) => {
                        if (response.data.error) {
                            alert(response.data.error);
                          } else {
                            setPollObjectLoad(response.data);
                            setPollObject(response.data);
                            setListOfPolls([...listOfPolls, response.data]);
                            const pollId = response.data.id;
                            axios.get(SERVER_URL+"/pollquestion/count/"+pollId).then((response) => {
                                if (!response.error)
                                    setQuestioncount(response.data);
                            });
                            axios.get(SERVER_URL+"/pollresponse/count/"+pollId).then((response) => {
                                if (!response.error)
                                    setResponsecount(response.data);
                            });
                            axios.get(SERVER_URL+"/poll/count/"+companyId).then((response) => {
                                if (!response.error)
                                    setPollscount(response.data);
                            });
                          }
                      });
                }
              });            
        }else{
            await axios.put(
                SERVER_URL+"/poll", {
                    name: PollObject.name,
                    enable: PollObject.enable,
                    randomcount: PollObject.randomcount,
                    description: PollObject.description,
                    finalmessage: PollObject.finalmessage,
                    pollphoto: PollObject.pollphoto,
                    resultemail: PollObject.resultemail,
                    showbranchinfo: PollObject.showbranchinfo,
                    showphoto: PollObject.showphoto,
                    usemetric: PollObject.usemetric,
                    MetricId: PollObject.MetricId,
                    useendlink: PollObject.useendlink,
                    endlink: PollObject.endlink,
                    nocompanyname: PollObject.nocompanyname,
                    color: PollObject.color,
                    id: PollObject.id
                },
                {
                  headers: { accessToken: localStorage.getItem("accessToken") },
                }
              ).then((response) => {
                if (response.data.error) {
                    alert(response.data.error);
                  } else {
                    setPollObject({...PollObject, enable:response.data.enable});
                    setPollObjectLoad(PollObject);
                    listOfPolls.find(obj => {
                        if(obj.id === PollObject.id){
                            obj.enable = response.data.enable;
                            obj.name = PollObject.name;
                        }
                    })
                  }
              }
    
              );
        }        
    };

    const getPollList = async (num, searchvar, companyIdvar) => {
        const extrafiltr = searchvar.length>0?searchvar:'all';
        try {
            axios.get(SERVER_URL+"/poll/partly/"+companyIdvar + "/" + num + "/" + extrafiltr + "/").then((response) => {
                setListOfPolls(response.data);
                if (response.data.length > 0){
                    setPollObject(response.data[0]);
                    setPollObjectLoad(response.data[0]);
                    setPostImage(response.data[0].pollphoto);
                    const pollId = response.data[0].id;
                    axios.get(SERVER_URL+"/pollquestion/count/"+pollId).then((response) => {
                        if (!response.error)
                            setQuestioncount(response.data);
                    });
                    axios.get(SERVER_URL+"/pollresponse/count/"+pollId).then((response) => {
                        if (!response.error)
                            setResponsecount(response.data);
                    });
                }                
            });
            axios.get(SERVER_URL+"/poll/count/"+companyIdvar).then((response) => {
                if (!response.error)
                    setPollscount(response.data);
            });
        } catch (error) {
            alert(error)
        }
    }

    useEffect(() => {
        axios.get(SERVER_URL+"/user/auth", {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.error) {
              alert(response.data.error);
            } else {
                setCompanyId(response.data.companyid);
                axios.get(SERVER_URL+"/poll/count/"+response.data.companyid).then((resp) => {
                    if (!resp.error)
                        setPollscount(resp.data);
                });
                axios.get(SERVER_URL+"/poll/partly/"+response.data.companyid + "/1/all/").then((response) => {
                    setListOfPolls(response.data);
                    if (response.data.length > 0){
                        setPollObject(response.data[0]);
                        setPollObjectLoad(response.data[0]);
                        setPostImage(response.data[0].pollphoto);
                        const pollId = response.data[0].id;
                        axios.get(SERVER_URL+"/pollquestion/count/"+pollId).then((response) => {
                            if (!response.error)
                                setQuestioncount(response.data);
                        });
                        axios.get(SERVER_URL+"/pollresponse/count/"+pollId).then((response) => {
                            if (!response.error)
                                setResponsecount(response.data);
                        });
                    }

                });            
            }
          });        
      }, []);

    const getPoll = async (uuid) =>{
        setResponsepagenum(1);
        setResponsesearch('');
        setResponsestatusfilter(-1);
        setListOfMetrics([]);
        axios.get(SERVER_URL+"/poll/byuuid/"+uuid).then((response) => {
            if (!response.error){
                setCompanyId(response.data.CompanyId);
                setPollObject(response.data);
                setPollObjectLoad(response.data);
                setPostImage(response.data.pollphoto);
                const pollId = response.data.id;
                    axios.get(SERVER_URL+"/pollquestion/count/"+pollId).then((response) => {
                        if (!response.error)
                            setQuestioncount(response.data);
                    });
                    axios.get(SERVER_URL+"/pollresponse/count/"+pollId).then((response) => {
                        if (!response.error)
                            setResponsecount(response.data);
                    });
                setKey('info');
            }

        });
    };
    
    function handleUpdateQuestion(questionObject) {
        if(questionObject!==null){
            if(questionStatus==='Edit'){
                listOfQuestions.find(obj => {
                    if(obj.id === questionObject.id){
                        obj.question = questionObject.question;
                        obj.QuestionTypeId = questionObject.QuestionTypeId;
                        obj.jsonobject = questionObject.jsonobject;
                        obj.pic = questionObject.pic;
                        obj.metricvalue = questionObject.metricvalue;
                    }
                });
                axios.put(
                    SERVER_URL+"/pollquestion",
                    {
                        id : questionObject.id,
                        question : questionObject.question,
                        QuestionTypeId : questionObject.QuestionTypeId,
                        jsonobject : questionObject.jsonobject,
                        pic : questionObject.pic,
                        metricvalue : questionObject.metricvalue
                    },
                    {
                      headers: { accessToken: localStorage.getItem("accessToken") },
                    }
                  ).then((response) => {
                    if (response.data.error) {
                        alert(response.data.error);
                      }
                  }       
                  );
            }else{
                if(questionStatus==='Del'){
                    //del to db
                    setListOfQuestions(listOfQuestions.filter(obj => obj.id !== questionObject.id));

                    axios.delete(
                        SERVER_URL+"/pollquestion/"+questionObject.id,
                        {
                          headers: { accessToken: localStorage.getItem("accessToken") },
                        }
                      ).then((response) => {
                        if (response.data.error) {
                            alert(response.data.error);
                          }
                      }       
                      );
                }else{
                    //add to db
                    var newNum = 0;
                    listOfQuestions.map((value, key) => {
                            newNum = Math.max(newNum, value.num)
                        });
                    questionObject.num = newNum+1;
                    axios.post(
                        SERVER_URL+"/pollquestion",
                        {
                            PollId : questionObject.PollId,
                            num : questionObject.num,
                            question : questionObject.question,
                            QuestionTypeId : questionObject.QuestionTypeId,
                            jsonobject : questionObject.jsonobject,
                            pic : questionObject.pic,
                            metricvalue : questionObject.metricvalue
                        },
                        {
                          headers: { accessToken: localStorage.getItem("accessToken") },
                        }
                      ).then((response) => {
                        if (response.data.error) {
                            alert(response.data.error);
                          }else{
                            setListOfQuestions([...listOfQuestions, response.data]);
                            const pollId = questionObject.PollId;
                            axios.get(SERVER_URL+"/pollquestion/count/"+pollId).then((response) => {
                                if (!response.error)
                                    setQuestioncount(response.data);
                            });
                            axios.get(SERVER_URL+"/pollresponse/count/"+pollId).then((response) => {
                                if (!response.error)
                                    setResponsecount(response.data);
                            });
                          }
                      }       
                      );                    
                }
            }
        }
        
        setShow(false);
    }
    
    const editQuestion = () =>{
        if(QuestionsObject.id!==0){
            setQuestionStatus('Edit');
            setShow(true);
        }
            
    };

    const delQuestion = () =>{
        if(QuestionsObject.id!==0){
            setQuestionStatus('Del');
            setShow(true);
        }            
    };

    const addQuestion = () =>{
        setQuestionsObject({...QuestionsObject, id:0,
            PollId: PollObject.id,
            question:"",
            num:0,
            QuestionTypeId:1,
            jsonobject:"{}",
            pic:"",
            metricvalue: 0});
            setQuestionStatus('Add');
        setShow(true);
    };

    const getQuestion = async (id) =>{
        axios.get(SERVER_URL+"/pollquestion/byid/"+id).then((response) => {
            if (!response.error){
                setQuestionsObject(response.data);
                setQuestionImage(questionImage);
            }

        });
    };

    const getmetricList = async () => {
        axios.get(SERVER_URL+"/metric/"+companyId).then((response) => {
            setListOfMetrics(response.data);            
        })
    };

    const getQuestionsList = async () =>{
        axios.get(SERVER_URL+"/pollquestion/"+PollObject.id).then((response) => {
            if (!response.error){
                setQuestionsObject({...QuestionsObject, id:0,
                    question:"",
                    num:0,
                    QuestionTypeId:0,
                    jsonobject:"",
                    pic:"",
                    metricvalue: 0});
                setQuestionImage(questionImage);
                setListOfQuestions(response.data);                
            }

        });
    };

    const getResponsessList = async (num, searchvar, statusvar) =>{
        const extrafiltr = searchvar.length>0?searchvar:'all';
        try {                            
            axios.get(SERVER_URL+"/pollresponse/partly/"+PollObject.id + "/" + num + "/" + statusvar + "/" + extrafiltr + "/", {
                headers: {
                accessToken: localStorage.getItem("accessToken"),
                },
            }).then((response) => {
                if (!response.error){
                    setListOfResponses(response.data);
                }

            });
            axios.get(SERVER_URL+"/pollresponse/count/"+PollObject.id).then((response) => {
                if (!response.error)
                    setResponsecount(response.data);
            });
        } catch (error) {
            alert(error)
        }
    };

    const getLinksList = async () =>{
        axios.get(SERVER_URL+"/user/auth", {
            headers: {
              accessToken: localStorage.getItem("accessToken"),
            },
          })
          .then((response) => {
            if (response.data.error) {
              alert(response.data.error);
            } else {
                axios.get(SERVER_URL+"/branch/activelinks/"+response.data.companyid).then((response) => {
                    if (!response.error){
                        setListOfLinks(response.data);                
                    }
                });
            };
        });
    };

    const addPoll = () => {
        setQuestioncount(0);
        setResponsecount(0);
        setKey('info');
        setResponsepagenum(1);
        setResponsesearch('');
        setResponsestatusfilter(-1);

        setPollObject({id:0,
            uuid:"",
            CompanyId:0,
            MetricId:0,
            name:"",
            description:"",
            finalmessage:"",
            pollphoto:"",
            resultemail:"",
            showbranchinfo: 0,
            showphoto: 0,
            enable: 0,
            default: 0,
            usemetric: 0,
            randomcount: 0,
            useendlink: 0,
            endlink: "",
            nocompanyname: 0,
            color: "#004A97"});
        setPollObjectLoad({id:0,
            uuid:"",
            CompanyId:0,
            MetricId:0,
            name:"",
            description:"",
            finalmessage:"",
            pollphoto:"",
            resultemail:"",
            showbranchinfo: 0,
            showphoto: 0,
            enable: 0,
            default: 0,
            usemetric: 0,
            randomcount: 0,
            useendlink: 0,
            endlink: "",
            nocompanyname: 0,
            color: "#004A97"});
        setPostImage('');
    }

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const fileSizeKiloBytes = file.size;
        if(fileSizeKiloBytes > 100000){
            alert("File size is greater than maximum limit 500Kb");
            return
          }

        if (file){
            const base64 = await convertToBase64(file);        
            setPostImage(base64);
            setPollObject({ ...PollObject, pollphoto: base64});            
        }        
      };
    
    return (
        <>
            <Menu ActiveKey="/polls"/>
            <Container fluid>
                <Row style={{marginTop:'15px'}}>
                    <Col sm={3} className="overflow-y-auto" style={{minWidth:'250px'}}>
                        <Row style={{margin:'3px'}}>
                            <Button size="lg" variant="primary" className="shadow" onClick={() => {addPoll()}}>
                                {t('poll.add')}{'  '}
                                <FileEarmarkPlusFill/>
                            </Button>
                        </Row>
                        <Row style={{margin:'3px'}} className="mt-2">
                            <Form.Control placeholder={t('app.Search')} value={pollssearch} onChange={(e)=>{setPollssearch(e.target.value);getPollList(pollspagenum, e.target.value, companyId)}}/>                            
                        </Row>
                        <ListGroup as="ol" role="button" style={{marginTop: '10px', maxHeight: '800px'}} className="overflow-y-auto shadow">
                            {listOfPolls.map((value, key) => {
                                   return(
                                        <ListGroup.Item key={key} action as="li" id={value.uuid} className="d-flex justify-content-between align-items-center" variant={value.uuid===PollObjectLoad.uuid? "primary": "outline-primary"}
                                            onClick={() =>{getPoll(value.uuid)}}>
                                            <Col xs={9}>
                                                <Row>
                                                <div className="col-11 text-truncate fs-6 fw-bolder">
                                                    {value.name}
                                                </div>
                                                </Row>                                                
                                            </Col>
                                            <Col xs={3} className="text-end">
                                                {!value.enable ? (
                                                    <Badge bg="danger" pill>
                                                        {t('app.Off')}
                                                    </Badge>
                                                ) : (
                                                    <Badge bg="success" pill>
                                                        {t('app.On')}
                                                    </Badge>
                                                )}                                                                                                                                            
                                            </Col>                                                                
                                        </ListGroup.Item>
                                   ) 
                                }
                            )}                            
                        </ListGroup>
                        <Row className="mt-4">
                            <ButtonToolbar className="justify-content-center"  style={{marginBottom:'10px'}}>
                                <ButtonGroup className="text-center shadow" size="sm">
                                <Button variant="outline-primary" onClick={()=>{if(pollspagenum>1){setPollspagenum(1); getPollList(1, pollssearch, companyId)}}}><SkipBackwardFill/></Button>
                                    <Button variant="outline-primary" onClick={()=>{if(pollspagenum>1){setPollspagenum(pollspagenum-1); getPollList(pollspagenum-1, pollssearch, companyId)}}}><CaretLeftFill/></Button>
                                    <Button variant="primary" disabled>{pollspagenum} {'  '} {t('app.From')} {'  '} {Math.ceil(pollscount/10)}</Button>
                                    <Button variant="outline-primary" onClick={()=>{if(pollspagenum*10<pollscount){setPollspagenum(pollspagenum+1); getPollList(pollspagenum+1, pollssearch, companyId)}}}><CaretRightFill/></Button>
                                    <Button variant="outline-primary" onClick={()=>{if(pollspagenum<Math.ceil(pollscount/10)){setPollspagenum(Math.ceil(pollscount/10)); getPollList(Math.ceil(pollscount/10), pollssearch, companyId)}}}><SkipForwardFill/></Button>
                                </ButtonGroup>
                            </ButtonToolbar>
                        </Row>                        
                    </Col>
                    <Col sm={9}>
                        <Row>
                            <Col sm={8}><h1>{t('poll.poll')} ({PollObjectLoad.id===0 ? t('app.create') : t('app.edit')}*)</h1></Col>
                            <Col sm={4} className="text-end" style={{marginTop:'2px'}}>
                                <Button variant={PollObject.name===PollObjectLoad.name&&
                                    PollObject.randomcount===PollObjectLoad.randomcount&&
                                    PollObject.description===PollObjectLoad.description&&
                                    PollObject.finalmessage===PollObjectLoad.finalmessage&&
                                    PollObject.resultemail===PollObjectLoad.resultemail&&
                                    PollObject.usemetric===PollObjectLoad.usemetric&&
                                    PollObject.showphoto===PollObjectLoad.showphoto&&
                                    PollObject.showbranchinfo===PollObjectLoad.showbranchinfo&&
                                    PollObject.enable===PollObjectLoad.enable&&
                                    PollObject.MetricId===PollObjectLoad.MetricId&&
                                    PollObject.pollphoto===PollObjectLoad.pollphoto&&
                                    PollObject.nocompanyname===PollObjectLoad.nocompanyname&&
                                    PollObject.color===PollObjectLoad.color&&
                                    PollObject.useendlink===PollObjectLoad.useendlink&&
                                    PollObject.endlink===PollObjectLoad.endlink? "outline-primary": "primary"} size="lg"
                                    onClick={() => {savePoll()}}>
                                {t('app.Save')}
                                </Button>{' '}                                
                            </Col>                            
                        </Row>
                        <Row style={{margin: '3px', marginTop: '10px'}}>
                            <Form.Group as={Row}>
                                <Form.Label column sm="2">{t('app.name')}</Form.Label>
                                    <Col sm="6">
                                        <Form.Control style={{fontWeight: "bolder"}} value={PollObject.name} onChange={(e) => { setPollObject({ ...PollObject, name: e.target.value})}}/>
                                    </Col>
                                    <Col sm="4">                                        
                                        {PollObjectLoad.id===0 ? '' : (
                                            <Form.Check style={{marginTop: '10px'}}                                           
                                                reverse 
                                                checked={PollObject.enable}
                                                type="switch"
                                                label={t('poll.enable')}
                                                onChange={(e) => { setPollObject({ ...PollObject, enable: e.target.checked})}}
                                            />
                                        )}                                        
                                    </Col>
                            </Form.Group>                            
                        </Row>
                        <Row style={{marginTop: '10px'}}>
                            <Tabs activeKey={key} onSelect={(k) => {
                                if(PollObjectLoad.id===0)
                                    {setKey('info')}
                                else{
                                    setKey(k);
                                    switch(k){
                                        case "questions":{getQuestionsList(); getmetricList(); break}
                                        case "links":{getLinksList(); break}
                                        case "responses":{getResponsessList(1, responsesearch, responsestatusfilter); break}                                        
                                    }
                                }
                                
                                }} className="mb-3">
                                <Tab eventKey="info" title={t('poll.info')}>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Check style={{marginTop: '10px', fontWeight: 'bolder'}}
                                                        checked={PollObject.showbranchinfo}
                                                        type="switch"
                                                        label={t('poll.showbranchinfo')}
                                                        onChange={(e) => { setPollObject({ ...PollObject, showbranchinfo: e.target.checked})}}
                                                    />
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Check style={{marginTop: '10px', fontWeight: 'bolder'}}
                                                        checked={PollObject.nocompanyname}
                                                        type="switch"
                                                        label={t('poll.nocompanyname')}
                                                        onChange={(e) => { setPollObject({ ...PollObject, nocompanyname: e.target.checked})}}
                                                    />
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.color')}</Form.Label>
                                                <Col sm="3">
                                                    <Form.Control readOnly value={PollObject.color}/>
                                                </Col>
                                                <Col>
                                                    <Form.Control
                                                        type="color"
                                                        id="pollColorInput"
                                                        value={PollObject.color}
                                                        onChange={(e) => { setPollObject({ ...PollObject, color: e.target.value})}}
                                                    />
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.randomcount')}</Form.Label>
                                                <Col sm="3">
                                                <Form.Control type="number" value={PollObject.randomcount} onChange={(e) => { setPollObject({ ...PollObject, randomcount: +e.target.value})}}/>
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '20px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.description')}</Form.Label>
                                                <Col sm="10">
                                                <Form.Control as="textarea" rows={3} value={PollObject.description} onChange={(e) => { setPollObject({ ...PollObject, description: e.target.value})}}/>
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.finalmessage')}</Form.Label>
                                                <Col sm="10">
                                                <Form.Control value={PollObject.finalmessage} onChange={(e) => { setPollObject({ ...PollObject, finalmessage: e.target.value})}}/>
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.endlink')}</Form.Label>
                                                <Col sm="5">
                                                    <Form.Control value={PollObject.endlink} onChange={(e) => { setPollObject({ ...PollObject, endlink: e.target.value})}}/>
                                                </Col>
                                                <Col sm={5}>
                                                    <Form.Check style={{marginTop: '10px'}}                                           
                                                        checked={PollObject.useendlink}
                                                        type="switch"
                                                        label={t('poll.useendlink')}
                                                        onChange={(e) => { setPollObject({ ...PollObject, useendlink: e.target.checked})}}
                                                    />
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.resultemail')}</Form.Label>
                                                <Col sm="5">
                                                <Form.Control value={PollObject.resultemail} onChange={(e) => { setPollObject({ ...PollObject, resultemail: e.target.value})}}/>
                                                </Col>
                                                <Col sm={5}>
                                                    <Form.Check style={{marginTop: '10px'}}                                           
                                                        checked={PollObject.showphoto}
                                                        type="switch"
                                                        label={t('poll.showphoto')}
                                                        onChange={(e) => { setPollObject({ ...PollObject, showphoto: e.target.checked})}}
                                                    />
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                    <Row style={{margin: '3px', marginTop: '10px'}}>
                                        <Form.Group as={Row}>
                                            <Form.Label column sm="2">{t('poll.pollphoto')}</Form.Label>
                                                <Col sm="10">
                                                <input id="myInput"
                                                    type="file"
                                                    accept=".jpeg, .png, .jpg"
                                                    ref={inputFile}
                                                    style={{display: 'none'}}
                                                    onChange={(e) => handleFileUpload(e)}/>
                                                <Image className="border shadow mt-4" role="button" alt={t('app.ClickToChoose')} src={`${postImage}`} width={128} height={128} onClick={()=>{inputFile.current.click()}}/>
                                                </Col>
                                        </Form.Group>                            
                                    </Row>
                                </Tab>
                                <Tab eventKey="questions" title={
                                        <React.Fragment>
                                            {t('poll.questions')} <Badge>{questioncount}</Badge>
                                        </React.Fragment>}>
                                    <Row>
                                        <Col sm={6} className="text-start">
                                            <Button size="sm"className="shadow" onClick={() => addQuestion()}><PlusCircle color="black"/>{'  '}{t('app.Add')}</Button>{'  '}
                                            <Button size="sm" onClick={() => delQuestion()} variant="outline-primary"><XCircleFill color="red"/>{'  '} {t('app.Del')}</Button>
                                            <OverlayTrigger
                                                placement="right"
                                                delay={{ show: 350, hide: 400 }}
                                                overlay={<Tooltip>{t('app.EditHint')}</Tooltip>}>
                                                    <Button onClick={() => editQuestion()} size="sm" variant="outline-primary" style={{marginLeft:"15px"}}><PencilSquare color="green"/>{'  '} {t('app.Edit')}</Button>
                                            </OverlayTrigger>                                                                                    
                                        </Col>
                                        <Col sm={6}>
                                                <InputGroup size='sm'>
                                                    <InputGroup.Text>{t('metric.metric')}</InputGroup.Text>
                                                    <Form.Select size="sm" value={!PollObject.MetricId?0:PollObject.MetricId}
                                                        onChange={(e) => {setPollObject({ ...PollObject, MetricId: parseInt(e.target.value, 10)})}}>
                                                            <option key={'none'} value={0}>{t('poll.nometric')}</option>
                                                            {listOfMetrics.map((value, key) => {
                                                                return(
                                                                    <option key={key} value={value.id}>{value.name}</option>                                                                
                                                                )
                                                            })}                                                    
                                                    </Form.Select>
                                                </InputGroup>                                                                                                                                        
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Table size="sm" responsive="sm" bordered hover style={{marginTop:'10px'}} role="button">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">{t('poll.questiontype')}</th>
                                                    <th className="text-center">{t('poll.question')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {listOfQuestions.map((value, key) => {
                                                return(
                                                    <tr key={key} onClick={(e) => {getQuestion(value.id)}} onDoubleClick={(e) => {editQuestion()}}>
                                                        <td style={value.id===QuestionsObject.id?({backgroundColor:"#cfe2ff", textAlign:"center"}):{textAlign:"center"}}>{questionTypes[value.QuestionTypeId-1]}</td>
                                                        <td style={value.id===QuestionsObject.id?({backgroundColor:"#cfe2ff", textAlign:"start"}):{textAlign:"start"}}>{value.question}</td>
                                                    </tr>
                                                    ) 
                                                }
                                            )}                                        
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                                <Tab eventKey="links" title={
                                    <React.Fragment>
                                        {t('poll.links')}                                        
                                    </React.Fragment>}
                                    >
                                    <Row>
                                        <Table size="sm" responsive="sm" bordered hover style={{marginTop:'10px', maxHeight: '500px', overflowY: 'auto'}} role="button">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">{t('poll.branch')}</th>
                                                    <th className="text-center">{t('poll.bystep')}</th>
                                                    <th className="text-center">{t('poll.onepage')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {listOfLinks.map((value, key) => {
                                                return(
                                                    <tr key={key}>
                                                        <td style={{textAlign:"start", fontSize: 'smaller', fontWeight:'bolder'}}>
                                                                {value.name}                                                            
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            <a
                                                                href={CLIENT_URL+'/poll/'+PollObject.uuid+'/'+value.uuid}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <span style={{marginRight:'15px'}}>{t('poll.openlink')}</span>
                                                            </a>
                                                            <a href={CLIENT_URL+'/print/poll/'+PollObject.uuid+'/'+value.uuid}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="btn btn-outline-primary btn-sm" role="button">{t('app.Print')}</a>                                                            
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            <a
                                                                href={CLIENT_URL+'/pollop/'+PollObject.uuid+'/'+value.uuid}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                <span style={{marginRight:'15px'}}>{t('poll.openlink')}</span>
                                                            </a>
                                                            <a href={CLIENT_URL+'/print/pollop/'+PollObject.uuid+'/'+value.uuid}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="btn btn-outline-primary btn-sm" role="button">{t('app.Print')}</a>                                                            
                                                        </td>
                                                    </tr>
                                                    ) 
                                                }
                                            )}                                        
                                            </tbody>
                                        </Table>
                                    </Row>
                                </Tab>
                                <Tab eventKey="responses" title={
                                    <React.Fragment>
                                        {t('poll.responses')} <Badge>{responsecount}</Badge>
                                    </React.Fragment>
                                    }>
                                    <Row>
                                        <Col sm={6}  style={{marginBottom:'10px', minWidth:'350px'}}>
                                            <ButtonToolbar>
                                                <ButtonGroup size="sm">
                                                    <Button variant={responsestatusfilter===1?"primary":"outline-primary"} onClick={()=>{responsestatusfilter===1?setResponsestatusfilter(-1):setResponsestatusfilter(1); setResponsepagenum(1);getResponsessList(1, responsesearch, responsestatusfilter===1?-1:1)}}>{responseStatus[0]}</Button>
                                                    <Button variant={responsestatusfilter===2?"primary":"outline-primary"} onClick={()=>{responsestatusfilter===2?setResponsestatusfilter(-1):setResponsestatusfilter(2); setResponsepagenum(1);getResponsessList(1, responsesearch, responsestatusfilter===2?-1:2)}}>{responseStatus[1]}</Button>
                                                    <Button variant={responsestatusfilter===3?"primary":"outline-primary"} onClick={()=>{responsestatusfilter===3?setResponsestatusfilter(-1):setResponsestatusfilter(3); setResponsepagenum(1);getResponsessList(1, responsesearch, responsestatusfilter===3?-1:3)}}>{responseStatus[2]}</Button>
                                                    <Button variant={responsestatusfilter===4?"primary":"outline-primary"} onClick={()=>{responsestatusfilter===4?setResponsestatusfilter(-1):setResponsestatusfilter(4); setResponsepagenum(1);getResponsessList(1, responsesearch, responsestatusfilter===4?-1:4)}}>{responseStatus[3]}</Button>
                                                </ButtonGroup>
                                            </ButtonToolbar>                                        
                                        </Col>
                                        <Col sm={6} style={{marginBottom:'10px'}}>
                                            <InputGroup size='sm'>
                                                <Form.Control placeholder={t('app.Search')} size="sm" type="text" value={responsesearch} onChange={(e)=>{setResponsesearch(e.target.value);getResponsessList(responsepagenum, e.target.value, responsestatusfilter)}}/>                                            
                                                <Button size="sm" onClick={() => {setResponsepagenum(1); getResponsessList(1, responsesearch, responsestatusfilter)}} variant="outline-primary"><ArrowClockwise/></Button>
                                            </InputGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Table size="sm" responsive="sm" bordered hover style={{maxHeight: '500px', overflowY: 'auto'}} role="button">
                                            <thead>
                                                <tr>
                                                    <th className="text-center">{t('response.clientinfo')}</th>
                                                    <th className="text-center">{t('response.metric')}</th>
                                                    <th className="text-center">{t('response.accesscode')}</th>
                                                    <th className="text-center">{t('response.status')}</th>
                                                    <th className="text-center">{t('response.branch')}</th>
                                                    <th className="text-center">{t('response.createdate')}/{t('response.updatedate')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {listOfResponses.map((value, key) => {
                                                return(
                                                    <tr key={key}>
                                                        <td style={{textAlign:"start", fontSize: 'smaller'}}>
                                                            <a
                                                                href={CLIENT_URL+'/response/admin/'+value.uuid}
                                                                target="_blank"
                                                                rel="noreferrer"
                                                            >
                                                                {value.email===''&&value.phone===''&&value.responsename===''?(<>{t('response.clientinfonodata')}</>):
                                                                (<><span style={{fontWeight: "bolder"}}>{value.email}{'  '}{value.phone}</span><br></br>
                                                                <span style={{fontWeight: "bolder"}}>{value.responsename}</span>
                                                                </>
                                                                )
                                                                }
                                                            </a>                                                                                                                
                                                        </td>
                                                        <td style={{textAlign:"center", fontSize: 'smaller'}}>
                                                            <span style={{fontWeight: "bolder"}}>{value.metricvalue}</span>                                                            
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            {value.adminaccesscode}                                                        
                                                        </td>
                                                        <td style={{textAlign:"center"}}>
                                                            <Badge bg={responseStatusColor[value.ResponseStatusId-1]}>
                                                                {responseStatus[value.ResponseStatusId-1]}
                                                            </Badge>
                                                        </td>
                                                        <td style={{textAlign:"center", fontSize: 'small'}}>
                                                            {value.name}<br/>
                                                            {value.extId}
                                                        </td>
                                                        <td style={{textAlign:"center", fontSize: 'small'}}>
                                                            {value.createdAt}<br/>
                                                            {value.updatedAt}
                                                        </td>
                                                    </tr>
                                                    ) 
                                                }
                                            )}                                        
                                            </tbody>
                                        </Table>
                                    </Row>
                                    <Row>
                                        <Col sm={4}></Col>
                                        <Col sm={4}>
                                            <ButtonToolbar>
                                                <ButtonGroup size="sm">
                                                    <Button variant="outline-primary" onClick={()=>{if(responsepagenum>1){setResponsepagenum(1); getResponsessList(1, responsesearch, responsestatusfilter)}}}><SkipBackwardFill/></Button>
                                                    <Button variant="outline-primary" onClick={()=>{if(responsepagenum>1){setResponsepagenum(responsepagenum-1); getResponsessList(responsepagenum-1, responsesearch, responsestatusfilter)}}}><CaretLeftFill/></Button>
                                                    <Button variant="primary" disabled>{responsepagenum} {'  '} {t('app.From')} {'  '} {Math.ceil(responsecount/10)}</Button>
                                                    <Button variant="outline-primary" onClick={()=>{if(responsepagenum*10<responsecount){setResponsepagenum(responsepagenum+1); getResponsessList(responsepagenum+1, responsesearch, responsestatusfilter)}}}><CaretRightFill/></Button>
                                                    <Button variant="outline-primary" onClick={()=>{if(responsepagenum<Math.ceil(responsecount/10)){setResponsepagenum(Math.ceil(responsecount/10)); getResponsessList(Math.ceil(responsecount/10), responsesearch, responsestatusfilter)}}}><SkipForwardFill/></Button>
                                                </ButtonGroup>
                                            </ButtonToolbar>
                                        </Col>
                                        <Col sm={4}></Col>
                                    </Row>
                                </Tab>
                            </Tabs>
                        </Row>                        
                    </Col>
                </Row>

                <Modal
                    show={show}
                    onHide={handleClose}
                    backdrop="static"
                    keyboard={false}
                    centered
                    fullscreen='md-down'
                    size="lg"
                >
                    <Modal.Body>
                        <QuestionConstructor question={QuestionsObject} questionStatus={questionStatus} onUpdateQuestion={handleUpdateQuestion}/>
                    </Modal.Body>                    
                </Modal>
            </Container>
        </>
    );
}

export default Polls;