import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import axios from "axios";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import RegisterFast from "./pages/RegisterFast";
import Main from "./pages/Main";
import Branches from "./pages/Branches";
import Polls from "./pages/Polls";
import Poll from "./pages/Poll";
import PollOP from "./pages/PollOP";
import ResponseAdmin from "./pages/ResponseAdmin";
import ResponseEdit from "./pages/ResponseEdit";
import Metrics from "./pages/Metrics";
import Print from "./pages/Print";
import Go from "./pages/Go";
import MorePoll from "./pages/MorePoll";
import MoreAPI from "./pages/MoreAPI";
import MoreMetric from "./pages/MoreMetric";
import MoreRegisterFast from "./pages/MoreRegisterFast";
import PageNotFound from "./pages/PageNotFound";
import MainHeader from "./components/MainHeader";
import Container from 'react-bootstrap/Container';

import { AuthContext } from "./helpers/AuthContext";
import { useState, useEffect } from "react";

import { SERVER_URL } from './helpers/constants'

function App() {
  const [authState, setAuthState] = useState({
    email: "",
    id: 0,
    companyid: 0,
    status: false,
  });

  useEffect(() => {
    axios.get(SERVER_URL+"/user/auth", {
        headers: {
          accessToken: localStorage.getItem("accessToken"),
        },
      })
      .then((response) => {
        if (response.data.error) {
          setAuthState({ ...authState, status: false });
        } else {
          setAuthState({
            email: response.data.email,
            id: response.data.id,
            companyid: response.data.companyid,
            status: true,
          });
        }
      });
  }, []);

  return (
    <Container fluid>
      <AuthContext.Provider value={{ authState, setAuthState }}>
      <MainHeader/>            
        <Router>
          <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="/login" exact element={<Login/>} />
            <Route path="/register" exact element={<Register/>} />
            <Route path="/registerfast/:link" exact element={<RegisterFast/>} />
            <Route path="/morepoll" exact element={<MorePoll/>} />
            <Route path="/moreapi" exact element={<MoreAPI/>} />
            <Route path="/moremetric" exact element={<MoreMetric/>} />
            <Route path="/moreregisterfast" exact element={<MoreRegisterFast/>} />
            <Route path="/main" exact element={<Main/>} />
            <Route path="/branches" exact element={<Branches/>} />
            <Route path="/polls" exact element={<Polls/>} />
            <Route path="/go/:link" exact element={<Go/>} />
            <Route path="/poll/:pollId/:branchId/:extId?" exact element={<Poll/>} />
            <Route path="/pollop/:pollId/:branchId/:extId?" exact element={<PollOP/>} />
            <Route path="/response/admin/:responseId" exact element={<ResponseAdmin/>} />
            <Route path="/response/edit/:responseId" exact element={<ResponseEdit/>} />
            <Route path="/print/:mode/:id1/:id2" exact element={<Print/>} />
            <Route path="/metrics" exact element={<Metrics/>} />
            <Route path="*" exact element={<PageNotFound/>} />
          </Routes>
        </Router>
      </AuthContext.Provider>      
    </Container>
  );
}

export default App;
