import { useTranslation } from 'react-i18next'
import { useNavigate } from "react-router-dom";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import Image from 'react-bootstrap/esm/Image';
import Card from 'react-bootstrap/Card';

function MoreRegisterFast() {
    const { t } = useTranslation();
    let navigate = useNavigate();

    return (
        <Container fluid style={{background: 'linear-gradient(#03369c,#ffffff)'}}>
            <Row style={{marginTop:'20px'}}>
                    <Col sm={1} xs={12}></Col>
                    <Col sm={8}  xs={12} className='mt-3 text-start' style={{color:'white'}}>
                        <span style={{marginLeft:'10px',fontSize:'3em', fontWeight:'bolder', color:'#FFB100'}} role='button' onClick={()=>{navigate('/')}}>{t('home.title')}</span><br/>
                        <span style={{marginLeft:'10px'}}>{t('home.text')}</span><br/>                                                
                    </Col>
                    <Col sm={2}  xs={12} className='mt-3 d-flex justify-content-center' style={{minWidth:'200px'}}>
                        <Image src="../logo.png" style={{marginLeft:'38px', width:'128px', height:'128px'}} role='button' onClick={()=>{navigate('/')}}/>                          
                    </Col>
            </Row>                         
            <Row>
                <Col className='text-center'>
                    <h1 style={{color:'white', marginTop:'15px'}}>{t('MoreRegisterFast.core')}</h1>
                </Col>
            </Row>
            <Row className='d-flex justify-content-center'>
                <Row style={{maxWidth:'1200px', marginBottom:'10px'}}>
                    <Col className='text-center'sm={6} xs={12} style={{marginTop:'20px'}}>
                        <Image fluid src='../fastreg.png'></Image>
                    </Col>                
                    <Col sm={6} xs={12} style={{marginTop:'20px'}}>
                        <Card>
                            <Card.Header>Активація QR-коду для отримання відгуків доступна після реєстрації.</Card.Header>
                            <Card.Body>
                                <Card.Text style={{color:'red', fontSize:'1.5em'}}>
                                    Безкоштовний період користування сервісом складає 5 днів. Для продовження терміну користування або активації додаткових підрозділів зв'яжіться з нами.
                                </Card.Text>
                                <Card.Title>Обов'язкові поля:</Card.Title>
                                <Card.Text>
                                    - Назва компанії;                                    
                                </Card.Text>
                                <Card.Text>
                                    - Назва підрозділу/магазину;                                    
                                </Card.Text>
                                <Card.Text>
                                    - Електронна адреса;                                    
                                </Card.Text>
                                <Card.Text>
                                    - Пароль;
                                </Card.Text>
                                <Card.Title>Додаткові поля</Card.Title>
                                <Card.Text>
                                    - Адреса підрозділу/магазину;
                                </Card.Text>                                
                                <Card.Title>Результати опитувань доступні в <Card.Link href="./main" style={{color:'black'}}>особистому кабінеті.</Card.Link></Card.Title>
                                <Card.Text>
                                    Використовуйте кабінет для налаштування опитувань додавши логотип компанії, опис підрозділу та опитування, перелік питань.
                                </Card.Text>                                                                
                            </Card.Body>
                        </Card>
                    </Col>                
                </Row>
                <Row style={{maxWidth:'1200px'}}>                    
                    <Card>
                        <Card.Body>
                            <Card.Title className='text-center'>Приклад налаштування форми опитування</Card.Title>
                            <Image fluid src='../polldata.png'></Image>
                        </Card.Body>
                    </Card>
                </Row>
                <Row style={{maxWidth:'1200px', marginBottom:'100px'}}></Row>
            </Row>
            <Row  className="fixed-bottom"  style={{height:'65px', backgroundColor:'#03369c'}}>
                <Col className="text-center mt-2">
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyEMail')}</span><br/>
                    <span style={{fontWeight:'bold', color:'#FFB100'}}>{t('app.CompanyPhone')}</span><br/>
                </Col>                
            </Row>
        </Container>        
    );
}

export default MoreRegisterFast;